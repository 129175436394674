import { useQuery } from 'react-query';
import {
  listSupplierCommand,
  supplierDetailCommand,
} from 'shared/commands/supplier.command';

export const useListSupplierQuery = (page = 1, other: any = {}) => {
  const queries = useQuery(['supplier list', page, other], async () => {
    const response = await listSupplierCommand(page, other);
    return response;
  });
  return queries;
};

export const useDetailSupplierQuery = (id: number) => {
  const queries = useQuery(
    ['supplier details', id],
    async () => {
      const response = await supplierDetailCommand(id);
      return response;
    },
    {
      enabled: id > 0,
    }
  );
  return queries;
};

import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import React from 'react';
import { RoleBroker } from 'shared/apis/token-broker';
import { userRolesPermissionsQuery } from 'shared/queries/user/users.query';

type typePermission =
  | 'po.list'
  | 'po.create'
  | 'po.update'
  | 'inbound.create'
  | 'po.change_status.approved'
  | 'po.change_status.paid'
  | 'po.change_status.finish'
  | 'warehouse.list'
  | 'warehouse.create'
  | 'warehouse.update'
  | 'stockcard.list'
  | 'stockcard.detail'
  | 'po.detail'
  | 'po.download.list'
  | 'user.list'
  | 'user.create'
  | 'user.update'
  | 'role.create'
  | 'role.list'
  | 'supplier.list'
  | 'po.download.detail'
  | 'po.download.list'
  | 'po.change_status.reject'
  | 'report.po.list';

function HasPermissionGroup<T = any>(
  props: {
    children: any;
    rule?: 'or' | 'and';
    permissions?: typePermission | typePermission[];
    as?: React.ComponentType<T>;
  } & React.ComponentProps<React.ComponentType<T>>
) {
  const [permissionList, setPermissionList] = useState<any[]>([]);
  const [allowed, setAllowed] = useState(true);

  const permissionRole = userRolesPermissionsQuery(RoleBroker.get() as any);
  const [permission, setPermission] = useState<typePermission[]>([]);

  useEffect(() => {
    const p = ((permissionRole.data?.data.data.list || []) as Array<any>).map(
      (item) => {
        return item.permission_slug;
      }
    );

    setPermissionList(p);
  }, [permissionRole.isSuccess]);

  useEffect(() => {
    // if (!props.permissions) {
    //   return;
    // }

    // if (isArray(props.permissions)) {
    //   setPermission(props.permissions);
    //   return;
    // }

    // setPermission([props.permissions]);
  }, [props.permissions]);

  useEffect(() => {
    permission.map((subper) => {
      if (allowed == false) {
        if(permissionList.includes(subper)) {
          setAllowed(true);
        }
      }
    });
  }, [permission, permissionList]);

  if ((permissionRole.isLoading || !allowed) && !isEmpty(permission)) {
    return <></>;
  }

  if (props.as) {
    return <props.as {...props} />;
  }

  return props.children;
}

export default HasPermissionGroup;

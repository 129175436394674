import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { listOutboundBySku } from 'shared/commands/outbound.command';
import downloadxls from 'shared/components/downloadxls';

function ExportOutbound(props: { id: number }) {
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  
  useEffect(() => {
    setIsLoading(true);
    listOutboundBySku(props.id, 1)
      .then((response) => {
        const mapData: any = [];
        const mapResponse = (response.data.data.list || []).map((item: any) =>
          (item.po || []).map((po: any) => ({ ...item, po }))
        );
        mapResponse.map((items: any) =>
          (items || []).map((item: any) => mapData.push(item))
        );

        setData(mapData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.id]);

  return (
    <Button onClick={()=>downloadxls('Generate Outbound.xlsx', data, [[
        'Nomor ID',
        'Nomor Order',
        'PO No',
        'Nomor SKU',
        'Nama Produk',
        'Harga Satuan',
        'Harga Total',
        'Stock dari',
        'Jumlah',
        'Nomor Mitra',
        'Nama Mitra',
        'Dibuat Pada',
        'Diupdate pada'
      ]])}>
      Download Excel
    </Button>
  );
}

export default ExportOutbound;

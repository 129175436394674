import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

import './products-manage.css';

const { Option } = Select;
const { TextArea } = Input;
const { Paragraph } = Typography;

function ProductsPage() {
  return (
    <>
      <Row justify='space-between' wrap={false} gutter={32}>
        <Col flex={1}>
          <Card
            title='Informasi Umum'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px' }}
          >
            {/* Brandowner */}
            <Form.Item label='Brandowner' required>
              <Select placeholder='Brandowner'>
                <Option value='dus'>Dus</Option>
                <Option value='berat'>Berat</Option>
              </Select>
            </Form.Item>
            {/* Fragie */}
            <Form.Item label='Fragie' required>
              <Radio.Group value={0}>
                <Space direction='vertical'>
                  <Radio value={0}>Ya</Radio>
                  <Radio value={1}>Tidak</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {/* Nama Produk */}
            <Form.Item label='Nama Produk' required>
              <Input placeholder='Nama Produk' />
            </Form.Item>
            {/* Deskripsi */}
            <Form.Item label='Deskripsi' required>
              <TextArea placeholder='Deskripsi' rows={5} />
            </Form.Item>
            {/* Kategori */}
            <Form.Item label='Kategori' required>
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Kategori'
              >
                <Option value='dus'>Dus</Option>
                <Option value='berat'>Berat</Option>
              </Select>
            </Form.Item>
            {/* Produk COD */}
            <Form.Item label='Produk COD' required>
              <Radio.Group value={0}>
                <Space direction='vertical'>
                  <Radio value={0}>Ya</Radio>
                  <Radio value={1}>Tidak</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card
            title='Opsi Varian'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px' }}
          >
            <Paragraph type='danger'>
              <b>Opsi Varian harus diisi jika ingin menambahkan varian.</b>{' '}
              Pastikan dalam mengisi varian, dilihat urutan pengisiannya agar
              tidak terjadi kesalahan ketika menyimpan data.
            </Paragraph>
            <Checkbox>
              Produk ini punya beberapa varian, seperti Warna atau Ukuran
            </Checkbox>
          </Card>
          <Card
            title='Pricing'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px' }}
          >
            {/* SKU */}
            <Form.Item label='SKU' required>
              <Input placeholder='SKU' disabled />
            </Form.Item>
            {/* Jenis Satuan */}
            <Form.Item label='Jenis Satuan' required>
              <Select placeholder='Jenis Satuan'>
                <Option value='dus'>Dus</Option>
                <Option value='berat'>Berat</Option>
              </Select>
            </Form.Item>
            {/* Stok */}
            <Form.Item label='SKU' required>
              <InputNumber min={0} />
            </Form.Item>
            {/* GPS */}
            <Form.Item label='Produk GPS' required>
              <Select placeholder='Produk GPS'>
                <Option value={0}>Tidak</Option>
                <Option value={1}>Ya</Option>
              </Select>
            </Form.Item>
            {/* Default */}
            <Form.Item label='Is Default' required>
              <Select placeholder='Is Default'>
                <Option value={0}>Tidak</Option>
                <Option value={1}>Ya</Option>
              </Select>
            </Form.Item>
            {/* Status */}
            <Form.Item label='Status' required>
              <Select placeholder='Status'>
                <Option value={0}>Tidak</Option>
                <Option value={1}>Ya</Option>
              </Select>
            </Form.Item>
            <Paragraph type='danger'>
              Pastikan harga dibawah tidak 0, ketika memilih status aktif
            </Paragraph>
            {/* Selling Price */}
            <Form.Item label='Selling Price' required>
              <InputNumber min={0} addonBefore='Rp' />
            </Form.Item>
            <Paragraph type='danger'>
              Harga jual yang diinput adalah harga jual sebelum diskon
            </Paragraph>
            {/* Presentase Diskon */}
            <Form.Item label='Presentase Diskon' required>
              <InputNumber min={0} max={100} addonAfter='%' />
            </Form.Item>
            <Paragraph type='danger'>
              Nilai diskon maskimal adalah 100
            </Paragraph>
            {/* Harga Pasaran */}
            <Form.Item label='Harga Pasaran' required>
              <InputNumber min={0} addonBefore='Rp' />
            </Form.Item>
            {/* Harga Distributor */}
            <Form.Item label='Harga Distributor' required>
              <InputNumber min={0} addonBefore='Rp' />
            </Form.Item>
            {/* Bonus Mingguan */}
            <Form.Item label='Bonus Mingguan' required>
              <Select placeholder='Bonus Mingguan'>
                <Option value={0}>Tidak</Option>
                <Option value={1}>Ya</Option>
              </Select>
            </Form.Item>
          </Card>
          <Card
            title='Commision'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Komisi Mitra 1 */}
            <Form.Item label='Komisi Mitra 1' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
            {/* Komisi Mitra 2 */}
            <Form.Item label='Komisi Mitra 2' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
            {/* Komisi Mitra 13 */}
            <Form.Item label='Komisi Mitra 3' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
            {/* Komisi Woobiz 1 */}
            <Form.Item label='Komisi Woobiz 1' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
            {/* Komisi Woobiz 2 */}
            <Form.Item label='Komisi Woobiz 2' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
            {/* Komisi Woobiz 13 */}
            <Form.Item label='Komisi Woobiz 3' required>
              <InputNumber min={0} value={0} addonBefore='Rp' />
            </Form.Item>
          </Card>
        </Col>
        <Col flex={5}>
          <Card
            title='Form Additional'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Status */}
            <Form.Item label='Status' required>
              <Select placeholder='Status'>
                <Option value={0}>Tidak</Option>
                <Option value={1}>Ya</Option>
              </Select>
            </Form.Item>
            <Paragraph type='danger'>
              Status ini berlaku untuk keseluruhan produk ini
            </Paragraph>
            <Form.Item label='Jenis Produk' required>
              <Checkbox>Produk ini adalah produk mikel</Checkbox>
            </Form.Item>
          </Card>
          <Card
            title='Mitra'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Hanya Mitra */}
            <Form.Item label='Hanya Mitra' required>
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Hanya Mitra'
              >
                <Option value={1}>Hanya Mitra</Option>
              </Select>
            </Form.Item>
            <Paragraph type='danger'>
              Jika produk ingin dilihat oleh seluruh mitra, kosongkan field ini
            </Paragraph>
          </Card>
          <Card
            title='Form Shipping Information'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Panjang */}
            <Form.Item label='Panjang (cm)' required>
              <InputNumber min={0} placeholder='0' addonBefore='cm' />
            </Form.Item>
            {/* Lebar */}
            <Form.Item label='Lebar (cm)' required>
              <InputNumber min={0} placeholder='0' addonBefore='cm' />
            </Form.Item>
            {/* Tinggi */}
            <Form.Item label='Tinggi (cm)' required>
              <InputNumber min={0} placeholder='0' addonBefore='cm' />
            </Form.Item>
            {/* Volume */}
            <Form.Item label='Volume (cm3)' required>
              <InputNumber min={0} placeholder='0' addonBefore='cm3' />
            </Form.Item>
            {/* Berat Set*/}
            <Form.Item label='Berat per set (kg)' required>
              <InputNumber min={0} placeholder='0' addonBefore='kg' />
            </Form.Item>
            {/* Berat Satuan*/}
            <Form.Item label='Berat per satuan (kg)' required>
              <InputNumber min={0} placeholder='0' addonBefore='kg' />
            </Form.Item>
            {/* Free Ongkir*/}
            <Form.Item label='Free Ongkir' required>
              <Radio.Group value={0}>
                <Space direction='vertical'>
                  <Radio value={0}>Ya</Radio>
                  <Radio value={1}>Tidak</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card
            title='Gudang dan Minimum Order'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Minimum Order */}
            <Form.Item label='Minimum Order' required>
              <InputNumber min={0} placeholder='0' />
            </Form.Item>
            <Paragraph type='danger'>Jumlah minimum untuk order</Paragraph>
          </Card>
          <Card
            title='Ketersediaan Tujuan Pengiriman'
            headStyle={{ backgroundColor: '#f5f5f5' }}
            style={{ marginBottom: '30px', width: '100%' }}
          >
            {/* Hanya Kota/Kabupaten */}
            <Form.Item label='Hanya Kota/Kabupaten' required>
              <Select mode='multiple' allowClear style={{ width: '100%' }}>
                <Option value='Yogyakarta'>Yogyakarta</Option>
              </Select>
            </Form.Item>
            <Paragraph type='danger'>
              Produk hanya bisa dikirim ke kota atau kabupaten yang dipilih.
            </Paragraph>
            <Paragraph type='danger'>
              Jika ingin produk ini bisa dikirim ke seluruh kota atau kabupaten,
              kosongkan field ini
            </Paragraph>
            {/* Kecuali Kota/Kabupaten */}
            <Form.Item label='Kecuali Kota/Kabupaten' required>
              <Select mode='multiple' allowClear style={{ width: '100%' }}>
                <Option value='Yogyakarta'>Yogyakarta</Option>
              </Select>
            </Form.Item>
            <Paragraph type='danger'>
              Produk dikirim ke seluruh kota atau kabupaten kecuali yang
              dipilih.
            </Paragraph>
            <Paragraph type='danger'>
              Jika ingin produk ini bisa dikirim ke seluruh kota atau kabupaten,
              kosongkan field ini
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ProductsPage;

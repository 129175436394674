import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRoleCommand, syncPermissionRole } from 'shared/commands/user.command';
import { userPermissionListQuery, userRoleListUserQuery } from 'shared/queries/user/users.query';

const { Option } = Select;

function CreateRolePage() {
  const [selectedPermissions, setSelectedPermissions]= useState<any>([]);
  const navigate = useNavigate();

  const roles = userRoleListUserQuery({
    page: 1,
    limit: -1,
  });

  const permissions = userPermissionListQuery({
    page: 1,
    limit: -1,
  });

  const onFinish = (data:any)=>{
    if((selectedPermissions as Array<any>).length == 0){
      message.warn('Permission tidak boleh kosong');
      return;
    }

    createRoleCommand(data).then((response)=>{
      syncPermissionRole({
        role_id: response.data.data.id,
        permission_id: selectedPermissions
      });

      permissions.refetch();
      roles.refetch();
      
      message.success("Role baru telah dibuat");
      navigate('/d/roles');
    }).catch((error) => {
      if(Object.keys(error.response.data.validation).length > 0){
        const keyname = Object.keys(error.response.data.validation)[0]
        message.error(error.response.data.validation[keyname]);
        return;
      }

      message.error(error.response.data.message);
    });
  }

  return (
    <>
      <Form
        name='dynamic_form_nest_item'
        autoComplete='off'
        layout='vertical'
        onFinish={onFinish}
      >
        <Card title='Peran Baru' style={{ marginBottom: '2em' }}>
          <Row gutter={52}>
            <Col xs={24} xl={12} span={12}>
              <Form.Item
                name='name'
                label='Nama Peran'
                rules={[
                  {
                    required: true,
                    message: 'Nama Peran harus diisi',
                  },
                  {
                    max: 25,
                    message: 'Nama Peran tidak boleh lebih dari 25 karakter'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Checkbox.Group style={{ width: '100%' }} name='permissions' onChange={setSelectedPermissions} value={selectedPermissions}>
          <Row gutter={52} style={{ marginBottom:50 }}>
            {permissions.data?.data.data.list.map((permission:any)=>(
              <Col xs={24} xl={6} span={6} style={{ marginBottom:12 }}>
                <Checkbox value={permission.id}>
                  {permission.title}
                </Checkbox>
              </Col>
            ))}
          </Row>
          </Checkbox.Group>
          <Button type='primary' htmlType='submit'>Simpan</Button>
        </Card>
      </Form>
    </>
  );
}

export default CreateRolePage;

import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

export interface List {
  id: number;
  name: string;
  address?: any;
  province?: any;
  city?: any;
  phone: string;
  bank_name: string;
  bank_account_name?: any;
  bank_account_number: string;
  created_at: string;
  updated_at?: any;
}

export interface Data {
  limit: number;
  list: List[];
  page: number;
  total: number;
  total_page: number;
  total_data: number;
}

export interface RootObject {
  status: number;
  message: string;
  data: Data;
}

const listSupplierCommand = <T>(page:number, other:any={}) => {
  return api<T, ApiResponse<Data>>(
    'GET',
    wrapApi('/supplier/v1/list',{page, ...other})
  );
};


export const supplierDetailCommand = <T>(id: number) => {
  return api<T, ApiResponse<any>>(
    'GET',
    wrapApi(`${`/supplier/v1/detail/${id}`}`)
  );
};

// POST METHOD

interface SupplierCreateRequest {
  supplier_name: string;
  supplier_phone: string;
  supplier_bank_name: string;
  supplier_bank_account_number: string;
};


const createSupplierCommand = <T = SupplierCreateRequest>(data: T) =>
  api<T, ApiResponse<any>>('POST', wrapApi(`/supplier/v1/create`), data);

export {
  createSupplierCommand,
  listSupplierCommand};
import {
  ContainerOutlined,
  LaptopOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import TokenBroker, { UserBroker } from 'shared/apis/token-broker';
import HasPermissionGroup from 'shared/components/has-permission';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const DashboardLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();
  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapsed}
        style={{
          height: '100vh',
          left: 0,
        }}
      >
        <div className='logo'>
          <img
            src={`${
              collapsed
                ? '/mobile-logo.png'
                : 'https://woobiz.id/wp-content/themes/woobiz-theme/assets/images/logo.svg'
            }`}
          />
        </div>
        <Menu theme='dark' mode='inline'>
          <HasPermissionGroup
            as={SubMenu}
            permissions={'po.list'}
            key='purhcaseorder'
            icon={<LaptopOutlined />}
            title='Settlement'
          >
            <HasPermissionGroup
              as={Menu.Item}
              key='5'
              permissions={'po.list'}
              onClick={() => {
                navigate('/d/purchase-orders');
              }}
            >
              Semua Settlement
            </HasPermissionGroup>
          </HasPermissionGroup>
          <HasPermissionGroup
            as={SubMenu}
            permissions={['warehouse.list', 'stockcard.list', 'supplier.list']}
            key='master'
            icon={<UserOutlined />}
            title='Master'
          >
            <HasPermissionGroup
              as={Menu.Item}
              permissions={'warehouse.list'}
              key='1'
              onClick={() => navigate('/d/warehouses')}
            >
              Gudang
            </HasPermissionGroup>
            <HasPermissionGroup
              as={Menu.Item}
              key='2'
              permissions={'stockcard.list'}
              onClick={() => navigate('/d/products')}
            >
              Kartu Stock
            </HasPermissionGroup>
            <HasPermissionGroup
              as={Menu.Item}
              key='4'
              onClick={() => navigate('/d/brand-suplier')}
              permissions={'supplier.list'}
            >
              Supplier
            </HasPermissionGroup>
          </HasPermissionGroup>
          <HasPermissionGroup
            as={SubMenu}
            key='report'
            icon={<ContainerOutlined />}
            title='Report'
            permissions={'report.po.list'}
          >
            <HasPermissionGroup
              as={Menu.Item}
              permissions={'report.po.list'}
              key='/d/reports/purchase-orders'
              onClick={() => navigate('/d/reports/purchase-orders')}
            >
              Settlement
            </HasPermissionGroup>
          </HasPermissionGroup>
          {/* <HasPermissionGroup
            as={SubMenu}
            key='management'
            icon={<UserOutlined />}
            title='Management'
            permissions={['user.list', 'role.list']}
          >
            <HasPermissionGroup
              as={Menu.Item}
              permissions={'user.list'}
              key='user'
              onClick={() => navigate('/d/users')}
            >
              User
            </HasPermissionGroup>
            <HasPermissionGroup
              as={Menu.Item}
              permissions={'role.list'}
              key='hak akses'
              onClick={() => navigate('/d/roles')}
            >
              Hak Akses
            </HasPermissionGroup>
          </HasPermissionGroup> */}
          <HasPermissionGroup
            as={Menu.Item}
            icon={<LogoutOutlined />}
            title='Gudang'
            key='logout'
            onClick={() => {
              TokenBroker.clear();
              window.location.reload();
            }}
          >
            Logout
          </HasPermissionGroup>
        </Menu>
      </Sider>
      <Layout className='site-layout'>
        <Header
          className='site-layout-background'
          style={{ padding: 0, textAlign: 'right', paddingRight: '24px' }}
        >
          <img
            style={{ marginRight: '10px' }}
            width={24}
            height={24}
            src={`https://avatars.dicebear.com/api/identicon/${UserBroker.get() || ""}.svg`}
          />{' '}
          {UserBroker.get() || ""}
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'auto' }}>
          <div style={{ padding: 4, textAlign: 'left' }}>
            <Outlet />
          </div>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;

import { Button, Card, Col, Input, Pagination, Row, Select, Space, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductStockQuery } from 'shared/queries/product/product.query';

const { Option } = Select;

function ProductsPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keyword, setKeyword] = useState("");
  const [sku, setSku] = useState("");

  const { data: productData, isLoading } = useProductStockQuery({ limit: 10, page: currentPage, sku, keyword });
  const navigate = useNavigate();

  const onSkuChange = (e:any)=>{
    setSku(e.target.value);
    setCurrentPage(1);
  }

  const onProductNameChange = (e:any)=>{
    setKeyword(e.target.value);
    setCurrentPage(1);
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a:any, b:any, index:any) => (index + 1 ) + ((currentPage - 1) * 10),
    },
    {
      title: 'SKU ID',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Nama Barang',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    // {
    //   title: 'Foto Produk',
    //   dataIndex: 'image',
    //   render: (image: any) => <img src={image} />,
    // },
    {
      title: 'Satuan',
      dataIndex: 'satuan',
      key: 'satuan',
    },
    {
      title: 'Total Stock',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Detail',
      key: 'action',
      render: (props: any) => (
        <Space size='middle'>
          <a onClick={() => navigate(`/d/products/${props.sku}`)}>Lihat Detail</a>
        </Space>
      ),
    },
  ];

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  }

  return (
    <>
      <Title level={3}>Daftar Barang</Title>
      <Card
        title={
          <Row justify='space-between' align='middle'>
            <Col flex={1}>
              <Row gutter={32}>
                <Col flex={1}>
                  <Input placeholder='Cari SKU' onChange={onSkuChange} />
                </Col>
                <Col flex={1}>
                  <Input placeholder='Cari nama produk' onChange={onProductNameChange} />
                  {/* <Select placeholder='Status' style={{ width: '100%' }}>
                    <Option value='active'>Aktif</Option>
                    <Option value='non-active'>Tidak Aktif</Option>
                  </Select> */}
                </Col>
              </Row>
            </Col>
            {/* <Col flex={1} style={{ textAlign: 'right' }}>
              <Button onClick={() => navigate('/d/products/manage')} type='primary'>
                Tambah Produk
              </Button>
            </Col> */}
          </Row>
        }
      >
        <Table
          loading={isLoading}
          scroll={{ x: 726 }}
          columns={columns}
          dataSource={productData?.data.data.list}
          bordered
          pagination={false}
        />
        <Row style={{ marginTop: "1rem" }} justify="end">
          <Col>
            <Pagination
            current={currentPage} 
            showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          } 
          onChange={handleChangePagination} 
          defaultCurrent={currentPage} 
          total={productData?.data.data.total} />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ProductsPage;

import { UploadOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Affix,
  Button,
  Card,
  Col,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InboundRequestType } from 'shared/commands/inbound.command';
import purchaseOrderCommand, {
  detailPurchaseOrderCommand,
  paymentVerificationCommand,
} from 'shared/commands/purchase-order.command';
import HasPermissionGroup from 'shared/components/has-permission';
import { useListInboundQuery } from 'shared/queries/inbound/inbound.query';
import { useListOutbound } from 'shared/queries/outbound/outbound.query';

import ExportInbound from './export-inbound';
import ExportOutbound from './export-outbound';
import InboundDetailDrawer from './inbound-detail-drawer';
import NewInboundDrawer from './inbound-drawer';
import MyDocument from './pdf';

const { Text, Title } = Typography;

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: (a: any, b: any, index: any) => index + 1,
  },
  {
    title: 'Nama Supplier',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    render: (text: any, row: any) => (
      <>
        <Row>
          <a>{text}</a>
        </Row>
        <Row>
          <a>{row.phone}</a>
        </Row>
      </>
    ),
  },
  {
    title: 'Nama Barang',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    render: (text: any) => (
      <>
        <Row>
          <span>Rp. {text.toLocaleString('id')}</span>
        </Row>
      </>
    ),
  },
  {
    title: 'Qty',
    key: 'qty',
    dataIndex: 'qty',
    render: (text: any) => (
      <>
        <Row>
          <span>{text.toLocaleString()}</span>
        </Row>
      </>
    ),
  },
  {
    title: 'Distribusi Stock',
    key: 'gudang',
    dataIndex: 'gudang',
    render: (text: any) => (
      <>
        <Row>
          <span>{text.toLocaleString()}</span>
        </Row>
      </>
    ),
  },
  {
    title: 'Subtotal',
    key: 'subtotal',
    dataIndex: 'subtotal',
    render: (text: any) => (
      <>
        <Row justify='end'>
          <span>Rp. {text.toLocaleString('id')}</span>
        </Row>
      </>
    ),
  },

];

function PreOrderDetailPage() {
  const [accModalVisibility, setAccModalVisibility] = useState(false);
  const [denyModalVisibility, setDenyModalVisibility] = useState(false);
  const [products, setProducts] = React.useState<any[]>([]);
  const [purchaseOrder, setPurchaseOrder] = React.useState<any>({});
  const [note, setNote] = React.useState<any>();
  const [tableData, setTableData] = useState([]);
  const [createInbound, setCreateInbound] = useState(false);
  const [inbondDetail, setInbondDetail] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState('') as any;
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [inbound, setInbound] = useState(false);

  const tableColumns = useMemo(
    () => [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
        render: (a: any, b: any, index: any) => index + 1,
      },
      {
        title: 'ID Inbound',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Tanggal Masuk',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Nama Barang',
        dataIndex: 'product',
        render: (product: any) => (
          <>
            {product.map((item: any) => (
              <div>{item.name}</div>
            ))}
          </>
        ),
      },
      {
        title: 'Stok Masuk',
        dataIndex: 'product',
        render: (product: any) => (
          <>
            {product.map((item: any) => (
              <div>
                {item.warehouse}: {item.stockIn} satuan
              </div>
            ))}
          </>
        ),
      },
      {
        title: 'Harga Beli',
        dataIndex: 'puchaseIn',
        render: (puchaseIn: any) => <>Rp. {puchaseIn.toLocaleString('id')}</>,
      },
      {
        title: 'Catatan',
        dataIndex: 'note',
        key: 'note',
      },
      {
        title: 'Status',
        render: (data: any) => {
          if (data.verified_by == 0) {
            return <Tag color={'orange'}>Pending</Tag>;
          }

          return data.is_verified == 0 ? (
            <Tag color={'red'}>Rejected</Tag>
          ) : (
            <Tag color={'green'}>Verified</Tag>
          );
        },
      },

      // {
      //   title: 'Aksi',
      //   key: 'product',
      //   render: (product: any) => {
      //     const product2:any = first(product.product) || {sku:0};
      //     console.log(inbondDetail);
      //     return (
      //       <Space size='middle' onClick={() => setInbondDetail(product2.sku)}>
      //         <a>Lihat Detail</a>
      //       </Space>
      //     )
      //   },
      // },
    ],
    []
  );

  const outboundColumn = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (day: string) => dayjs(day).format('DD-MM-YYYY'),
    },
    {
      title: 'Order No',
      dataIndex: 'order_no',
      key: 'order_no',
    },
    {
      title: 'Nama Mitra',
      dataIndex: 'mitra_name',
      key: 'mitra_name',
    },
    {
      title: 'Nomor Mitra',
      dataIndex: 'mitra_phone',
      render: (data: any) => (
        <Space size='middle'>
          <div>{data}</div>
        </Space>
      ),
    },
    {
      title: 'Kuantitas',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Grand Total',
      dataIndex: 'grand_total',
      key: 'grand_total',
      render: (grand_total: number) => (
        <>
          <p>Rp. {grand_total.toLocaleString('id')}</p>
        </>
      ),
    },
  ];

  const { id } = useParams();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<InboundRequestType>({
    page: 1,
    limit: 10,
  });

  const [filterOutbound, setFilterOutbound] = useState<InboundRequestType>({
    page: 1,
    limit: 10,
  });

  const inboundQuery = useListInboundQuery(filter);
  const outboundQuery = useListOutbound(filterOutbound);

  const refetchData = useCallback(() => {
    setLoading(true);
    detailPurchaseOrderCommand(Number(id))
      .then((response) => {
        setPurchaseOrder(response.data.data.row);
        const statusPO = response.data.data.row.status;
        const paymentMethod:string = response.data.data.row.supplier_payment || "";
        if (statusPO == 3 || statusPO == 2 || statusPO == 9) {
          setInbound(true);
        }
        if (statusPO == 1) {
          if (paymentMethod.includes("COD") || paymentMethod.includes("TOP")) {
            setInbound(true);
          }
          if (
            (paymentMethod.includes('CBD') && response.data.data.row.is_paid == 1) || paymentMethod.includes('CBD')
          ) {
            setInbound(true);
          }
        }
        if (statusPO == 0) {
          setInbound(false);
        }
        const poProducts = (response.data.data.row.Product || []).map(
          (product: any, index: any) => {
            return {
              key: index,
              product_name: product.product_name,
              qty: product.qty_alocation,
              price: product.unit_price_real,
              subtotal: product.sub_total,
              is_complete: product.is_complete,
              satuan: product.satuan,
              supplier_name: response.data.data.row.supplier_name,
              phone: '-',
              gudang: product.qty_real,
            };
          }
        );

        setProducts(poProducts);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    refetchData();
  }, []);

  useEffect(() => {
    setFilter({ ...filter, po_id: id });
    setFilterOutbound({ ...filter, po_id: id });
  }, [id]);

  useEffect(() => {
    const inboundData =
      (inboundQuery.data?.data.data.list || []).map((inboundItem) => {
        return {
          key: inboundItem.id,
          id: inboundItem.id,
          origin: inboundItem.po.id,
          date: dayjs(inboundItem.created_at).format('DD-MM-YYYY'),
          product: [
            {
              name: inboundItem.product.name,
              stockIn: inboundItem.qty_arrival,
              sku: inboundItem.product.sku,
              warehouse: inboundItem.warehouse.name,
            },
          ],
          puchaseIn: inboundItem.product.price_real,
          note: inboundItem.note,
          is_verified: inboundItem.is_verified,
          verified_by: inboundItem.verified_by,
        };
      }) || [];

    setTableData(inboundData as any);
  }, [inboundQuery.data?.data.data]);

  useEffect(() => {
    const inboundData =
      (inboundQuery.data?.data.data.list || []).map((inboundItem) => {
        return {
          key: inboundItem.id,
          id: inboundItem.id,
          origin: inboundItem.po.id,
          date: dayjs(inboundItem.created_at).format('DD-MM-YYYY'),
          product: [
            {
              name: inboundItem.product.name,
              stockIn: inboundItem.qty_arrival,
              sku: inboundItem.product.sku,
              warehouse: inboundItem.warehouse.name,
            },
          ],
          puchaseIn: inboundItem.product.price_real,
          note: inboundItem.note,
          is_verified: inboundItem.is_verified,
          verified_by: inboundItem.verified_by,
        };
      }) || [];

    setTableData(inboundData as any);
  }, [inboundQuery.data?.data.data]);

  function onDatePickerChange(date: any, dateString: any) {
    setFilter({ ...filter, transaction_date: dateString });
  }

  function onPaginationChange(pageNumber: number) {
    setFilter({ ...filter, page: pageNumber });
  }

  function onPaginationChangeOutbound(pageNumber: number) {
    setFilterOutbound({ ...filterOutbound, page: pageNumber });
  }
  function onPaginationShowSizeChangeOutbound(current: number, pageSize: number) {
    setFilterOutbound({ ...filterOutbound, limit: pageSize });
  }

  function onPaginationShowSizeChange(current: number, pageSize: number) {
    setFilter({ ...filter, limit: pageSize });
  }

  const handleNoteChange = (values: any) => {
    setNote(values.currentTarget.value);
  };

  const acceptPurchaseOrder = (values: any) => {
    purchaseOrderCommand
      .changeStatusPurchaseOrderRequest(values.id, {
        status: 1,
        note: '',
      })
      .then(() => {
        refetchData();
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });

    setAccModalVisibility(false);
  };

  const markAsPaid = () => {
    purchaseOrderCommand
      .changeStatusPurchaseOrderRequest(id as any, {
        status: 2,
      })
      .then(() => {
        refetchData();
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  const rejectPurchaseOrder = (values: any) => {
    purchaseOrderCommand
      .changeStatusPurchaseOrderRequest(values.id, {
        status: 9,
        note: note,
      })
      .then(() => {
        refetchData();
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
    setDenyModalVisibility(false);
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadPayment = async (info: any) => {
    const imageBase64 = await getBase64(info.file.originFileObj);
    setImageBase64(imageBase64);
  };

  const handleUploadPaymentSubmit = () => {
    setLoadingUpload(true);
    paymentVerificationCommand(Number(id), { image: imageBase64 })
      .then(() => {
        refetchData();
        setImageBase64('');
        setLoadingUpload(false);
        message.success('Upload berhasil');
        refetchData();
      })
      .catch(() => {
        message.error('An error occured');
        setLoadingUpload(false);
      });
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Card
        style={{ marginBottom: '30px' }}
        title={
          <Row justify='space-between'>
            <Col>
              <Row justify='start' gutter={32} align='middle'>
                <Col>
                  <Text strong>Detail Purchase Order</Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <PDFDownloadLink
                document={<MyDocument purchaseOrder={purchaseOrder} />}
                fileName={`${purchaseOrder.number}.pdf`}
              >
                {({ loading }) =>
                  loading ? 'Loading document...' : 'Download PDF'
                }
              </PDFDownloadLink>
            </Col>
          </Row>
        }
      >
        <Row wrap>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Nama User</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.created_by_name}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>ID Settlement</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.number}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Status</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.status_label}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Nama Gudang</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.wh_name}</Title>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Nomor Handphone</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.created_by_phone || '-'}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Tanggal pengajuan Settlement</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.created_at}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Tanggal PO dibayar</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.paid_at || '-'}</Title>
            </Row>
          </Col>
          <Col xs={24} xl={6} span={8}>
            <Row>
              <Col>
                <Text type='secondary'>Metode Pembayaran</Text>
              </Col>
            </Row>
            <Row>
              <Title level={5}>{purchaseOrder.supplier_payment}</Title>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card title='Daftar Produk Purchase Order'>
        <Table
          columns={columns}
          dataSource={products}
          bordered
          scroll={{ x: 768 }}
          pagination={false}
          footer={() => (
            <Row justify='end'>
              <Col span={5  }>
                Grand Total
              </Col>
              <Col span={2}>
                Rp {purchaseOrder.grand_total && purchaseOrder.grand_total.toLocaleString('id')}  
              </Col>
            </Row>
          )}
        />
      </Card>
      {purchaseOrder.payment_image && (
        <Card style={{ marginTop: '2rem' }} title='Bukti Pembayaran'>
          <img src={purchaseOrder.payment_image} width='200' />
        </Card>
      )}
      <Affix offsetBottom={0} offsetTop={0}>
        <Card style={{ textAlign: 'right', marginBottom: '24px' }}>
          <Row gutter={18}>
            {purchaseOrder.status == 0 ||
              (purchaseOrder.status == 1 && (
                <Col>
                {/* TODO: ADD PERMISSION */}
                <HasPermissionGroup
                  as={Button}
                  onClick={() => setDenyModalVisibility(true)}
                  type='primary'
                  danger
                >
                    Tolak PO
                </HasPermissionGroup>
                </Col>
              ))}
            {purchaseOrder.status == 1 && !purchaseOrder.payment_image && (
              <Col>
                {/* TODO: ADD PERMISSION */}
                <HasPermissionGroup>
                  <Row gutter={12}>
                    <Col>
                      <Upload
                        accept='.jpg,.png'
                        customRequest={dummyRequest}
                        onChange={handleUploadPayment}
                      >
                        <Button icon={<UploadOutlined />}>
                          Unggah Bukti Pembayaran
                        </Button>
                      </Upload>
                    </Col>
                    {imageBase64 && (
                      <Col>
                        <Button
                          loading={loadingUpload}
                          onClick={handleUploadPaymentSubmit}
                          type='primary'
                        >
                          Kirim
                        </Button>
                      </Col>
                    )}
                  </Row>
                </HasPermissionGroup>
              </Col>
            )}
            {purchaseOrder.status == 0 && (
              <Col>
                {/* TODO: ADD PERMISSION */}
                <HasPermissionGroup as={Button}
                  onClick={() =>
                    navigate(`/d/purchase-orders/${purchaseOrder.id}/edit`)
                  }
                  type='ghost'
                >
                    Edit Purchase Order
                </HasPermissionGroup>
              </Col>
            )}
            {purchaseOrder.status == 0 && (
              <Col>
                <HasPermissionGroup as={Button} 
                  permissions={'po.change_status.approved'}
                  onClick={() => setAccModalVisibility(true)}
                  type='primary'
                >
                  Acc PO
                </HasPermissionGroup>
              </Col>
            )}
          </Row>
        </Card>
      </Affix>

      {inbound && (
        <>
          {inboundQuery.data?.data.data.list.length !== 0 && (
            <div className='' style={{ marginBottom: '1em' }}>
              <ExportInbound filename='inbound-list.xlsx' id={id as any} />
            </div>
          )}
          {!['3', '9'].includes(purchaseOrder.status) && (
            <Card>
              <Button onClick={() => setCreateInbound(true)}>
                Inbound Stok
              </Button>
            </Card>
          )}
          <Table
            loading={inboundQuery.isFetching}
            columns={tableColumns}
            dataSource={tableData}
            bordered
            scroll={{ x: 768 }}
            pagination={false}
          />
          <Card>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              current={filter.page}
              defaultCurrent={2}
              total={inboundQuery.data?.data.data.total || 0}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationShowSizeChange}
            />
          </Card>

          <div className='' style={{ marginBottom: '1em', marginTop:'2em' }}>
            <h2>Outbound by Settlement</h2>
            <ExportOutbound filename='outbound-list.xlsx' id={id as any} />
          </div>
          <Table
            loading={outboundQuery.isFetching}
            columns={outboundColumn}
            dataSource={outboundQuery.data?.data.data.list || []}
            bordered
            scroll={{ x: 768 }}
            pagination={false}
          />
          <Card>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showQuickJumper
              current={filterOutbound.page}
              defaultCurrent={2}
              total={outboundQuery.data?.data.data.total || 0}
              onChange={onPaginationChangeOutbound}
              onShowSizeChange={onPaginationShowSizeChangeOutbound}
            />
          </Card>
        </>
      )}

      <div style={{ marginBottom:'200px' }}></div>

      {/* Modal Section */}
      <>
        <Modal
          closable={false}
          centered
          footer={null}
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          visible={accModalVisibility}
          width={300}
          onOk={() => setAccModalVisibility(false)}
          onCancel={() => () => setAccModalVisibility(false)}
        >
          <h3>Konfirmasi</h3>
          <p>Apakah kamu yakin akan melakukan acc pada PO ini?</p>
          <Row gutter={18} justify='space-between'>
            <Col>
              <Button
                onClick={() => setAccModalVisibility(false)}
                type='primary'
                ghost
              >
                Batalkan
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => acceptPurchaseOrder(purchaseOrder)}
                type='primary'
              >
                Ya, Acc
              </Button>
            </Col>
          </Row>
        </Modal>

        <Modal
          closable={false}
          centered
          footer={null}
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          visible={denyModalVisibility}
          width={300}
          onOk={() => setDenyModalVisibility(false)}
          onCancel={() => () => setDenyModalVisibility(false)}
        >
          <h3>Alasan Pembatalan</h3>
          <TextArea
            style={{ margin: '10px 0 20px 0' }}
            placeholder='Isi alasan pembatalan'
            onChange={handleNoteChange}
          />
          <Row gutter={18} justify='space-between'>
            <Col>
              <Button
                onClick={() => setDenyModalVisibility(false)}
                type='primary'
                ghost
              >
                Kembali
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => rejectPurchaseOrder(purchaseOrder)}
                type='primary'
              >
                Batalkan PO
              </Button>
            </Col>
          </Row>
        </Modal>
        <NewInboundDrawer
          show={createInbound}
          poId={id as any}
          warehouseId={purchaseOrder.warehouse_id}
          onChange={setCreateInbound}
          onSuccess={() => inboundQuery.refetch()}
        />
        <InboundDetailDrawer
          show={inbondDetail != 0}
          inboundId={inbondDetail}
          onChange={setInbondDetail}
        />
      </>
      {/* End Modal Section */}
    </>
  );
}

export default PreOrderDetailPage;

import { Button } from 'antd';
import { useEffect, useState } from 'react';
import {
  poInboundReport,
} from 'shared/commands/purchase-order.command';
import downloadxls from 'shared/components/downloadxls';

function ExportReportPO(props: {
  limit?: number;
  page?: number;
  sort_by?: 'number' | 'created_at' | 'paid_at' | 'finished_at';
  dir?: 'ASC' | 'DESC';
  q?: string;
  is_paid?: 1 | 0;
}) {
  const [, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    setIsLoading(true);
    poInboundReport({ ...props, limit: 999999, page: 1 })
      .then((response) => {
        const list: any[] = response.data?.data.list || [];
        setData(
          list.map((listItem) => ({
            ...listItem,
            is_paid: listItem.is_paid == '1' ? 'Terbayar' : 'Belum Dibayar',
          }))
        );

        // res=>({
        //   is_paid:res.is_paid:"Terbayar":"Belum Dibayar"
        // })
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props]);

  return (
    <Button onClick={()=>downloadxls('Laporan Purchase Order.xlsx', data)}>
        Download Excel
    </Button>
  );
}

export default ExportReportPO;

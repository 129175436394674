import { Card, Col, Pagination, Row, Spin, Table } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListSupplierQuery } from 'shared/queries/supplier/supplier.query';


function BrandSuplierIndexPage() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: supplierList, isLoading } = useListSupplierQuery(currentPage);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a:any, b:any, index:any) => (index + 1 ) + ((currentPage - 1) * 10),
    },
    {
      title: 'ID Brand',
      dataIndex: 'id',
    },
    {
      title: 'Nama Brand',
      dataIndex: 'name',
    },
    {
      title: 'No. Telepon',
      dataIndex: 'phone',
    },
    {
      title: 'Logo Brand',
      dataIndex: 'logo',
    },
    {
      title: 'Metode Pembayaran',
      dataIndex: 'payment_method',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
  ];

  const toAddSupplier = () => {
    navigate('/d/brand-suplier/create');
  }

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) return <Spin />;

  return (
    <>
      <Card 
      // title={<Row justify="end">
      //   <Col>
      //     <Button onClick={toAddSupplier} type="primary">Tambah brand baru</Button>
      //   </Col>
      // </Row>}
      >
        <Table style={{ marginTop: "1rem" }} pagination={false} columns={columns} dataSource={supplierList?.data.data.list}  />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              current={currentPage}
              total={supplierList?.data.data.total_data}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showSizeChanger={false}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default BrandSuplierIndexPage;

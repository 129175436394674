import {
  Button,
  Card,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HasPermissionGroup from 'shared/components/has-permission';
import { userListUserQuery } from 'shared/queries/user/users.query';

const { Title } = Typography;

function UserPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: users, isLoading } = userListUserQuery({
    page: currentPage,
    limit: 10,
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a: any, b: any, index: any) =>
        index + 1 + (currentPage - 1) * 10,
    },
    {
      title: 'Nama Lengkap',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Role',
      key: 'role',
      render: (data: any) => {
        return data.role.name;
      },
    },
    {
      title: 'Status',
      render: (data: any) => {
        let color = 'geekblue';
        const status = parseInt(data.status.value);

        if ([1, 2, 3].includes(status)) {
          color = 'green';
        }

        if ([8, 9].includes(status)) {
          // Close, Reject
          color = 'volcano';
        }

        return (
          <>
            <Tag color={color} key={status} style={{ marginBottom: '8px' }}>
              {data.status.label}
            </Tag>
            <br />
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data: any) => (
        <>
          {/* <Row gutter={[16, 16]}>
            <Col>
              <Button
                type='primary'
                onClick={() => navigate('/d/users/' + data.id)}
              >
                Ubah
              </Button>
            </Col>
          </Row> */}
        </>
      ),
    },
  ];

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Title level={3}>Daftar Pengguna</Title>
      <Card
        title={
          <Row>
            <Col></Col>
            <Col>
              <HasPermissionGroup
                as={Button}
                permissions={'user.create'}
                type='primary'
                onClick={() => navigate('/d/users/create')}
              >
                Buat Pengguna Baru
              </HasPermissionGroup>
            </Col>
          </Row>
        }
      >
        <Table
          columns={columns}
          dataSource={users?.data.data.list}
          bordered
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
        />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={currentPage}
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              total={users?.data.data.total_data}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default UserPage;

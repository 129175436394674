import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

export type WarehouseListRequest = {
  page?: number;
  limit?: number;
  keyword?: string;
  status?: number;
};
export interface WarehouseListResponse {
  id: number;
  name: string;
  area: string;
  address: string;
  status: string;
  status_label: string;
  created_at: string;
  updated_at?: any;
}
export interface Datable {
  limit: number;
  list: WarehouseListResponse[];
  page: number;
  total: number;
  total_page: number;
}

export const listWarehouseCommand = <T = WarehouseListRequest>(data: T) => {
  const tempData = data as any;

  const requestData = {
    ...tempData,
  };

  return api<T, ApiResponse<Datable>>(
    'GET',
    wrapApi('/warehouse/v1/list', requestData)
  );
};

export const listAreaWarehouseCommand = <T>() => {
  return api<T, ApiResponse<Datable>>(
    'GET',
    wrapApi('/warehouse/v1/list_area')
  );
};


/**
 * POST Api section warehouse
 */

type WarehouseCreateRequest = {
  id: number;
  name: string;
  area: string;
  address: string;
  status: number;
};

export const createWarehouseRequest = <T = WarehouseCreateRequest>(data: T) =>
  api<T, ApiResponse<any>>('POST', wrapApi('/warehouse/v1/create'), data);

export const editWarehouseRequest = <T = WarehouseCreateRequest>(id: number, data: T) =>
  api<T, ApiResponse<any>>('PUT', wrapApi(`/warehouse/v1/update/${id}`), data);

export default {
  listWarehouseCommand,
  // put the rest export in here
};

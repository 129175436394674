/* eslint-disable simple-import-sort/imports */
import {
  Button,
  Card,
  DatePicker,
  Pagination,
  Row,
  Space,
  Table,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InboundRequestType } from 'shared/commands/inbound.command';
import { useListInboundQuery } from 'shared/queries/inbound/inbound.query';
import dayjs from 'dayjs';
import Title from 'antd/lib/typography/Title';

const tableColumns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: (a:any, b:any, index:any) => index + 1,
  },
  {
    title: 'Asal PO',
    dataIndex: 'origin',
    key: 'origin',
  },
  {
    title: 'Tanggal Masuk',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Nama Barang',
    dataIndex: 'product',
    render: (product: any) => (
      <>
        {product.map((item: any) => (
          <div>{item.name}</div>
        ))}
      </>
    ),
  },
  {
    title: 'Stok Masuk',
    dataIndex: 'product',
    render: (product: any) => (
      <>
        {product.map((item: any) => (
          <div>Gudang: {item.stockIn} satuan</div>
        ))}
      </>
    ),
  },
  {
    title: 'Harga Beli',
    dataIndex: 'puchaseIn',
    render: (puchaseIn: any) => <>Rp. {puchaseIn}</>,
  },
  {
    title: 'Catatan',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Aksi',
    key: 'action',
    render: () => (
      <Space size='middle'>
        <a>Lihat Detail</a>
      </Space>
    ),
  },
];

function InboundPage() {
  const [filter, setFilter] = useState<InboundRequestType>({
    page: 1,
    limit: 10,
  });
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const inboundQuery = useListInboundQuery(filter);

  useEffect(() => {
    const page = filter.page !== undefined && filter.page - 1;
    const limit = filter.limit !== undefined && filter.limit;
    const inboundData =
      (inboundQuery.data?.data.data.list || []).map((inboundItem, index) => {
        return {
          key: (page as any) * (limit as any) + index,
          id: inboundItem.id,
          origin: inboundItem.po.id,
          date: dayjs(inboundItem.transaction_datetime).format('DD-MM-YYYY'),
          product: [
            {
              name: inboundItem.product.name,
              stockIn: inboundItem.product.qty_real,
            },
          ],
          puchaseIn: inboundItem.product.price_real,
          note: inboundItem.note,
        };
      }) || [];

    setTableData(inboundData as any);
  }, [inboundQuery.data?.data.data]);

  function onDatePickerChange(date: any, dateString: any) {
    setFilter({ ...filter, transaction_date: dateString });
  }

  function onPaginationChange(pageNumber: number) {
    setFilter({ ...filter, page: pageNumber });
  }

  function onPaginationShowSizeChange(current: number, pageSize: number) {
    setFilter({ ...filter, limit: pageSize });
  }

  return (
    <>
      <Title level={3}>Daftar Inbound Produk</Title>
      <Card
        title={
          <Row justify='space-between' align='middle'>
            <DatePicker bordered={false} onChange={onDatePickerChange} />
            <Button
              type='primary'
              onClick={() => navigate('/d/inbounds/stock')}
            >
              Inbound Stock
            </Button>
          </Row>
        }
      >
        <Table
          loading={inboundQuery.isFetching}
          columns={tableColumns}
          dataSource={tableData}
          bordered
          pagination={false}
          style={{ marginBottom: '24px' }}
        />
        <Pagination
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showQuickJumper
          defaultCurrent={2}
          total={inboundQuery.data?.data.data.total || 0}
          onChange={onPaginationChange}
          onShowSizeChange={onPaginationShowSizeChange}
        />
      </Card>
    </>
  );
}

export default InboundPage;

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Radio,
  RadioChangeEvent,
  Result,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';
import {
  createWarehouseRequest,
  editWarehouseRequest,
} from 'shared/commands/warehouse.command';
import HasPermissionGroup from 'shared/components/has-permission';
import { useListAreaWarehouseQuery, useListWarehouseQuery } from 'shared/queries/warehouse/warehouse.query';

const { Option } = Select;

function WarehousePage() {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const warehouseQueryArea = useListAreaWarehouseQuery();
  const [warehouseStatus, setWarehouseStatus] = useState<number>(1);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [modalEditVisible, setModalEditVisible] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [warehouseId, setWarehouseId] = useState<number>(0);

  const {
    data: warehouses,
    isLoading,
    refetch,
  } = useListWarehouseQuery({
    status: warehouseStatus,
    keyword: keyword,
    page: currentPage,
    limit: 10,
  });

  const [form] = Form.useForm();
  // const [collapsed, setCollapse] = useState(false);

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a: any, b: any, index: any) =>
        index + 1 + (currentPage - 1) * 10,
    },
    {
      title: 'Kode Gudang',
      dataIndex: 'id',
      key: 'id',
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Nama Gudang',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status Gudang',
      dataIndex: 'status_label',
      key: 'status_label',
    },
    {
      title: 'Alamat Gudang',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: any) => (
        <Space size='middle'>
          <HasPermissionGroup permissions={'warehouse.update'}>
            <a onClick={() => handleOpenModalEdit(data)}>Ubah</a>
          </HasPermissionGroup>
        </Space>
      ),
    },
  ];

  const handleOpenModalGudang = () => {
    setModalVisible(true);
  };

  function onChange(e: RadioChangeEvent) {
    setWarehouseStatus(e.target.value);
  }

  const handleOpenModalEdit = (prevData: any) => {
    form.setFieldsValue({
      name: prevData.name,
      area: prevData.area,
      address: prevData.address,
      status: parseInt(prevData.status),
    });
    setWarehouseId(prevData.id);
    setModalEditVisible(true);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    createWarehouseRequest(values)
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        setModalVisible(false);
        setModalSuccess(true);
        form.resetFields();
        refetch();
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const onFinishEdit = (values: any) => {
    setLoading(true);
    editWarehouseRequest(warehouseId, values)
      .then(() => {
        setLoading(false);
      })
      .then(() => {
        setModalEditVisible(false);
        setModalSuccess(true);
        form.resetFields();
        refetch();
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const onCancel = () => {
    setModalVisible(false);
    setModalEditVisible(false);
    form.resetFields();
  };

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  const onKeywordChange = (e:any)=>{
    setKeyword(e.target.value);
    setCurrentPage(1);
  }

  return (
    <>
      <Title level={3}>Daftar Gudang</Title>
      <Card
        title={
          <Row>
            <Col flex="auto">
              <Row justify='start' gutter={32} align='middle' style={{ width:"100%" }}>
                <Col>
                  <Radio.Group
                    style={{ textAlign: 'center' }}
                    options={[
                      { label: 'Gudang Aktif', value: 1 },
                      { label: 'Gudang Non-Aktif', value: 0, style: {} },
                    ]}
                    onChange={onChange}
                    value={warehouseStatus}
                    optionType='button'
                  ></Radio.Group>
                </Col>
                <Col xs={24} xl={12} span={12}>
                  <Input placeholder='Ketikan kata kunci pencarian' onChange={onKeywordChange} />
                </Col>
              </Row>
            </Col>
            <Col>
              <HasPermissionGroup as={Button} 
                permissions={'warehouse.create'}
                onClick={handleOpenModalGudang} type='primary'
              >
                Tambah Gudang
              </HasPermissionGroup>
            </Col>
          </Row>
        }
      >
        <Table
          scroll={{ x: 768 }}
          loading={isLoading}
          columns={columns}
          dataSource={warehouses?.data.data.list}
          bordered
          pagination={false}
        />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={currentPage}
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              total={warehouses?.data.data.total}
            />
          </Col>
        </Row>
      </Card>

      {/* Add warehouse modal */}
      <Modal
        centered
        title='Detail Gudang'
        closable={false}
        footer={null}
        visible={modalVisible}
      // onOk={onFinish}
      // onCancel={() => setModalVisible(false)}
      >
        <Form
          form={form}
          name='form'
          layout='vertical'
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            name='name'
            label='Nama Gudang'
            rules={[
              { required: true, message: 'Isi Nama Gudang' },
              {
                type: 'string',
                min: 6,
                message: 'Nama Gudang minimal 6 karakter',
              },
            ]}
          >
            <Input placeholder='Nama gudang' />
          </Form.Item>
          <Form.Item
            name='area'
            label='Area'
            hasFeedback
            rules={[{ required: true, message: 'Pilih area gudang' }]}
          >
            <Select placeholder='Mohon pilih area gudang'>
              <Option value='jakarta'>Jakarta</Option>
              <Option value='bandung'>Bandung</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='address'
            label='Detail Alamat Gudang'
            rules={[{ required: true, message: 'Isi detail Alamat Gudang' }]}
          >
            <Input.TextArea
              placeholder='Detail Alamat Gudang'
              showCount
              maxLength={100}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Pilih status gudang' }]}
            name='status'
            label='Status Gudang'
          >
            <Radio.Group>
              <Row gutter={32}>
                <Col>
                  <Radio value={1}>Aktif</Radio>
                </Col>
                <Col>
                  <Radio value={0}>Non-aktif</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Row justify='end' gutter={20}>
            <Col>
              <Button onClick={() => onCancel()} type='ghost'>
                Batalkan
              </Button>
            </Col>
            <Col>
              <Button loading={loading} type='primary' htmlType='submit'>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Edit warehouse modal */}
      <Modal
        closable={false}
        centered
        title='Detail Gudang'
        cancelText='Batalkan'
        okText='Update'
        footer={null}
        visible={modalEditVisible}
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinishEdit}
          // onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            name='name'
            label='Nama Gudang'
            rules={[
              { required: true, message: 'Isi Nama Gudang' },
              {
                type: 'string',
                min: 6,
                message: 'Nama Gudang minimal 6 karakter',
              },
            ]}
          >
            <Input placeholder='Nama gudang' />
          </Form.Item>
          <Form.Item
            name='area'
            label='Area'
            hasFeedback
            rules={[{ required: true, message: 'Pilih area gudang' }]}
          >
            <Select placeholder='Pilih area gudang'>
              <Option value='jakarta'>Jakarta</Option>
              <Option value='bandung'>Bandung</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='address'
            label='Detail Alamat Gudang'
            rules={[{ required: true, message: 'Isi detail Alamat Gudang' }]}
          >
            <Input.TextArea
              placeholder='Detail Alamat Gudang'
              showCount
              maxLength={100}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Pilih status gudang' }]}
            name='status'
            label='Status Gudang'
          >
            <Radio.Group>
              <Row gutter={32}>
                <Col>
                  <Radio value={1}>Aktif</Radio>
                </Col>
                <Col>
                  <Radio value={0}>Non-aktif</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>
          <Row justify='end' gutter={20}>
            <Col>
              <Button onClick={() => onCancel()} type='ghost'>
                Batalkan
              </Button>
            </Col>
            <Col>
              <Button loading={loading} type='primary' htmlType='submit'>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Success Modal */}
      <Modal centered closable={false} visible={modalSuccess} footer={null}>
        <Result
          status='success'
          title='Berhasil'
          subTitle='Gudang baru berhasil ditambahkan'
          extra={[
            <Button
              onClick={() => setModalSuccess(false)}
              type='primary'
              key='console'
            >
              OKE
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
}

export default WarehousePage;
import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

import { UserType } from '../../../type';

export type AddressCreateRequest = {
  phone:string;
  password:string;
}
export interface LoginResponse {
  token: string;
  user:  UserType;
}


function login<T=AddressCreateRequest>(requestData:T){
  return api<T, ApiResponse<LoginResponse>>('POST', wrapApi("/users/v1/login"), requestData);
}

export default { 
  login, 
  // put the rest export in here
};
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { poBySkuCommand } from 'shared/commands/purchase-order.command';
import downloadxls from 'shared/components/downloadxls';

function ExportPOHistory(props: { id: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileIsReady, setFileIsReady] = useState(false);
  const [data, setData] = useState([]);
  const header = [[
    'ID Settlement',
    'Nomor Settlement',
    'ID Gudang',
    'Gudang',
    'Nomor SKU',
    'Nama Produk',
    'Jumlah Alokasi',
    'Harga Beli',
    'Jumlah Asli',
    'Stok',
    'Dibuat Pada',
    'Pembuat',
    'Status ID',
    'Status',
  ]];
  
  useEffect(() => {
    setIsLoading(true);
    poBySkuCommand(props.id, 1, 999999999999999)
      .then((response) => {
        const localData = response.data?.data.list || [];

        setData(localData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.id]);

  return (
    <Button onClick={()=>downloadxls('Generate History PO.xlsx', data, header)}>
      Download Excel
    </Button>
  );
}

export default ExportPOHistory;

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import { useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { createUserCommand } from 'shared/commands/user.command';
import { userRoleListUserQuery } from 'shared/queries/user/users.query';

const { Option } = Select;

function CreateUserPage() {
  const navigate = useNavigate();
  const [validation, setValidation] = useState<any>({});

  const roles = userRoleListUserQuery({
    page: 1,
    limit: 100,
  });

  const onFinish = (data:any)=>{
    createUserCommand(
      {
        role_id: data.role.value,
        fullname: data.name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        conf_password: data.password_confirm,
        status: 1,
      }
    ).then(()=>{
      message.success("user baru tersimpan");
      navigate('/d/users');
    }).catch((error) => {
      if(Object.keys(error.response.data.validation).length > 0){
        const keyname = Object.keys(error.response.data.validation)[0]
        message.error(error.response.data.validation[keyname]);

        return;
      }

      message.error(error.response.data.message);
    });
  }

  return (
    <>
      <Form
        name='dynamic_form_nest_item'
        autoComplete='off'
        layout='vertical'
        onFinish={onFinish}
      >
        <Card title='Pengguna Baru' style={{ marginBottom: '2em' }}>
          <Row gutter={52}>
            <Col xs={24} xl={12} span={12}>
              <Form.Item
                name='name'
                label='Nama Lengkap'
                rules={[
                  {
                    required: true,
                    message: 'Nama Lengkap harus diisi',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='email'
                label='Email'
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: 'Alamat email tidak valid',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='phone'
                label='Phone'
                rules={[
                  {
                    required: true,
                    message: 'Ponsel tidak boleh kosong',
                  },
                  {
                    min:8,
                    message:'Nomor ponsel minimum 8 karakter'
                  }
                ]}
              >
                <Input type={'number'} style={{ width:'100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12} span={12}>
            <Form.Item
                name='password'
                label='Password'
                help='Password harus mengandung, huruf kapital, nomor dan symbol'
                rules={[
                  {
                    required: true,
                    message: 'Password harus diisi',
                  },
                ]}
              >
                <Input type={'password'} />
              </Form.Item>

              <Form.Item
                name='password_confirm'
                label='Konfirmasi Password'
                rules={[
                  {
                    required: true,
                    message: 'Konfirmasi harus diisi',
                  },
                ]}
              >
                <Input type={'password'} />
              </Form.Item>
              <Form.Item
                name='role'
                label='Peran'
                rules={[
                  {
                    required: true,
                    message: 'Peran harus diisi',
                  },
                ]}
              >
                <Select
                  labelInValue
                  placeholder='Ketikan Peranan'
                  showSearch
                  filterOption={false}
                >
                  {(roles.data?.data.data.list||[]).map((role: any) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Button type='primary' htmlType='submit'>Simpan</Button>
        </Card>
      </Form>
    </>
  );
}

export default CreateUserPage;

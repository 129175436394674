import { isEmpty } from "lodash";
import { Navigate, useLocation } from "react-router-dom";
import { tokenBroker } from "shared/apis";

function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();
  
    if (isEmpty(tokenBroker.get())) {
      return <Navigate to="/auth/login" state={{ from: location }} />;
    }
  
    return children;
}

export default RequireAuth;
import { Descriptions, Drawer, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { useInboundQuery } from './../../../../shared/queries/inbound/inbound.query';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: (a:any, b:any, index:any) => index + 1 ,
  },
  {
    title: 'Nama Supplier',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    render: (text: any, row: any) => (
      <>
        <Row>
          <a>{text}</a>
        </Row>
        <Row>
          <a>{row.phone}</a>
        </Row>
      </>
    ),
  },
  {
    title: 'Nama Barang',
    dataIndex: 'product_name',
    key: 'product_name',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    render: (text: any) => (
      <>
        <Row>
          <span>Rp. {text.toLocaleString()}</span>
        </Row>
      </>
    ),
  },
  {
    title: 'Qty',
    key: 'qty',
    dataIndex: 'qty',
    render: (text: any) => (
      <>
        <Row>
          <span>{text.toLocaleString()}</span>
        </Row>
      </>
    ),
  },
  {
    title: 'Distribusi Stock',
    key: 'gudang',
    dataIndex: 'gudang',
  },
];

const InboundDetailDrawer = (props: {
  show: boolean;
  inboundId: string | number;
  onChange: (value: any) => void;
}) => {
  const [onSending, setOnSending] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const inboundQuery = useInboundQuery(props.inboundId || 0);

  useEffect(() => {
    const data = inboundQuery.data?.data.data;
    setProducts([
      {
        supplier_name: data?.supplier.name || '',
        phone: '',
        product_name: data?.product.name || '',
        price: data?.product.price_real || 0,
        qty: data?.qty_arrival || 0,
        gudang: data?.warehouse.name || '',
      },
    ]);
  }, [inboundQuery.data?.data.data]);

  return (
    <>
      <Drawer
        title='Detil Inbound'
        width={isMobile ? 320 : 720}
        onClose={() => {
          props.onChange(false);
        }}
        visible={props.show}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Table
          columns={columns}
          dataSource={products}
          bordered
          scroll={{ x: 768 }}
          pagination={false}
        />
      </Drawer>
    </>
  );
};

export default InboundDetailDrawer;

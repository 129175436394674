import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

export enum PurchaseOrderStatus {
  New = 0,
  Approve = 1,
  Paid = 2,
  Finish = 3,
  Close = 8,
  Reject = 9,
}

export type PurchaseOrderListRequest = {
  page?: number;
  limit?: number;
  start_date?: string;
  end_date?: string;
  sort_by?: string;
  dir?: string;
  q?: string;
  status?: PurchaseOrderStatus;
};
export interface PurchaseOrderListResponse {
  id: number;
  number: string;
  status: number;
  status_label: string;
  supplier_id: string;
  supplier_name: string;
  product_count: number;
  grand_total: number;
  created_by: number;
  supplier_phone: number;
  supplier_bank_account_number: number;
  supplier_bank_name: number;
  created_by_name: string;
  created_at: string;
  appoved_by?: any;
  appoved_at?: any;
  paid_by?: any;
  paid_at?: any;
  rejected_by?: any;
  rejected_at?: any;
  po_note?: any;
  finished_by?: any;
  finished_at?: any;
  updated_at?: any;
  updated_by?: any;
  Product?: any;
  Activity?: any;
  warehouse_id?: any;
  is_paid?: any;
  supplier_payment?: any;
}
export interface Datatable {
  limit: number;
  list: PurchaseOrderListResponse[];
  page: number;
  total: number;
  total_page: number;
}

export const listPurchaseOrderCommand = <T = PurchaseOrderListRequest>(
  data: T
) => {
  const tempData = data as any;

  const requestData = {
    ...tempData,
  };

  return api<T, ApiResponse<Datatable>>(
    'GET',
    wrapApi('/po/v1/list', requestData)
  );
};

export const detailPurchaseOrderCommand = (poNumber: number) => {
  return api<any, ApiResponse<{ row: PurchaseOrderListResponse }>>(
    'GET',
    wrapApi(`/po/v1/detail/${poNumber}`)
  );
};

type PurchaseOrderCreateRequest = {
  supplier_name: string;
  supplier_phone: string;
  supplier_bank_name: string;
  supplier_bank_account_number: string;
  product: PurchaseOrderProduct[];
};

type PaymantVerification = {
  image: string;
};

type PurchaseOrderProduct = {
  product_name: string;
  product_sku: string;
  product_satuan: string;
  product_buy_price: number;
  product_buy_qty: number;
};

type PurchaseOrderChangeStatusRequest = {
  status: string;
  note: string;
};

export const createPurchaseOrderRequest = <T = PurchaseOrderCreateRequest>(
  data: T
) => api<T, ApiResponse<any>>('POST', wrapApi('/po/v1/create'), data);

export const editPurchaseOrderRequest = <T = PurchaseOrderCreateRequest>(
  id: number,
  data: T
) => api<T, ApiResponse<any>>('PUT', wrapApi(`/po/v1/update/${id}`), data);

export const changeStatusPurchaseOrderRequest = <
  T = PurchaseOrderChangeStatusRequest
>(
  id: number,
  data: T
) =>
  api<T, ApiResponse<any>>('PUT', wrapApi(`/po/v1/change_status/${id}`), data);

export const poBySkuCommand = <T = PurchaseOrderChangeStatusRequest>(
  id: number | string,
  page: number,
  limit = 10
) =>
  api<T, ApiResponse<any>>(
    'GET',
    wrapApi(`/po/v1/po_by_sku/${id}?page=${page}&limit=${limit}`)
  );

export const poInboundReport = <
  T = {
    limit: number;
    page: number;
    sort_by: 'number' | 'created_at' | 'paid_at' | 'finished_at';
    dir: 'ASC' | 'DESC';
    q: string;
    is_paid: 1 | 0;
    start_date?:string;
    end_date?:string;
  }
>(
  data: T
) =>
  api<T, ApiResponse<any>>(
    'GET',
    wrapApi(`/report/v1/po_inbound`, { ...data })
  );

export const paymentVerificationCommand = <T = PaymantVerification>(
  id: number,
  data: T
) =>
  api<T, ApiResponse<any>>(
    'PUT',
    wrapApi(`/po/v1/payment_verification/${id}`),
    data
  );

export default {
  listPurchaseOrderCommand,
  detailPurchaseOrderCommand,
  createPurchaseOrderRequest,
  editPurchaseOrderRequest,
  changeStatusPurchaseOrderRequest,
  poBySkuCommand,
  poInboundReport,
  // put the rest export in here
};

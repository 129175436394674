import { useQuery } from 'react-query';
import { detailRoleCommand, detailUserCommand, listPermissionCommand, listPermissionOfRoles, listRolesCommand, listUserCommand, listUserRequest } from 'shared/commands/user.command';

export const userListUserQuery = (data:listUserRequest) => {
  const queries = useQuery(['user list', data],
    async () => {
      const response = await listUserCommand(data);
      return response;
    });
  return queries;
};

export const userQuery = (id:number) => {
  const queries = useQuery(['user', id],
    async () => {
      const response = await detailUserCommand(id);
      return response;
    });
  return queries;
};

export const userRoleListUserQuery = (data:listUserRequest) => {
  const queries = useQuery(['role list', data],
    async () => {
      const response = await listRolesCommand(data);
      return response;
    });
  return queries;
};

export const userPermissionListQuery = (data:listUserRequest) => {
  const queries = useQuery(['permission list', data],
    async () => {
      const response = await listPermissionCommand(data);
      return response;
    });
  return queries;
};

export const userRoleQuery = (id:number) => {
  const queries = useQuery(['roles', id],
    async () => {
      const response = await detailRoleCommand(id);
      return response;
    });
  return queries;
};

export const userRolesPermissionsQuery = (id:number) => {
  const queries = useQuery(['roles-permission', id],
    async () => {
      const response = await listPermissionOfRoles(id);
      return response;
    });
  return queries;
};
import { Col, Layout, Row } from 'antd';
import { Outlet } from 'react-router-dom';

function AuthLayout() {
  return (
    <Layout style={{ height: '100vh' }}>
      <Row
        justify='center'
        align='middle'
        style={{ height: '100%', width: '100%' }}
      >
        <Col xs={18} md={12} lg={8}>
          <Outlet />
        </Col>
      </Row>
    </Layout>
  );
}
export default AuthLayout;

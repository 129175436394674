import { isEmpty } from 'lodash';

export default (url:string, queryParams:any = {}) => {
  let qp = Object.keys(queryParams).map((key) => `${key}=${queryParams[key]}`).join('&');
  if (!isEmpty(qp)) {
    qp = `?${qp}`;
  }

  return `${process.env.REACT_APP_API_ENDPOINT}${url}${qp}`;
};

import {
  Button,
  Card,
  Col,
  Pagination,
  Row,
  Table,
  Tag,
  Typography,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HasPermissionGroup from 'shared/components/has-permission';
import { userRoleListUserQuery } from 'shared/queries/user/users.query';

const { Title } = Typography;

function RolesPage() {
  const navigate = useNavigate();
  const [keywords, setKeywords] = useState('');
  const [paymentStatus, setPaymentStatus] = useState<number | string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: users, isLoading } = userRoleListUserQuery({
    page: currentPage,
    limit: 10,
  });


  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a: any, b: any, index: any) => (index + 1) + ((currentPage - 1) * 10),
    },
    {
      title: 'Peran',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data: any) => (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                type='primary'
                onClick={() => navigate('/d/roles/'+data.id)}
              >
                Edit
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Title level={3}>Daftar Peran</Title>
      <Card
      title={
        <Row>
          <Col></Col>
          <Col>
            <HasPermissionGroup as={Button} 
              permissions={'role.create'}
              type='primary'
              onClick={() => navigate('/d/roles/create')}
            >
              Buat Peran Baru
            </HasPermissionGroup>
          </Col>
        </Row>
        }
      >
        <Table
          columns={columns}
          dataSource={users?.data.data.list}
          bordered
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
        />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={currentPage}
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              total={users?.data.data.total_data}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default RolesPage;

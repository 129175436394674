/**
 * You guys can write the pages routes here.
 */
import AuthLayout from 'pages/auth.layout';
import LoginPage from 'pages/auth/login.page';
import DashboardLayout from 'pages/dashboard.layout';
import BrandSuplierIndexPage from 'pages/dashboard/brand-suplier/brand-suplier-index/brand-suplier-index.page';
import CreateBrandPage from 'pages/dashboard/brand-suplier/create-brand/create-brand.page';
import InboundPage from 'pages/dashboard/inbound';
import InboundStockPage from 'pages/dashboard/inbound/inbound-stock';
import ProductTypesPage from 'pages/dashboard/product-type';
import ProductsPage from 'pages/dashboard/products';
import ProductStockDetailPage from 'pages/dashboard/products/product-stock-detail/product-stock-detail.page';
import ProductsManagePage from 'pages/dashboard/products/products-manage';
import CreatePurchaseOrderPage from 'pages/dashboard/purchase-order/create-purchase-order/create-purchase-order.page';
import EditPurchaseOrderPage from 'pages/dashboard/purchase-order/edit-purchase-order/edit-purchase-order.page';
import PurchaseOrderPage from 'pages/dashboard/purchase-order/purchase-order-index';
import PreorderDetail from 'pages/dashboard/purchase-order/purchase-order-show';
import ReportPurchaseOrder from 'pages/dashboard/reports/purchase-order-index';
import RolesPage from 'pages/dashboard/roles';
import CreateRolePage from 'pages/dashboard/roles/create-role';
import EditRolePage from 'pages/dashboard/roles/edit-role';
import UserPage from 'pages/dashboard/user';
import CreateUserPage from 'pages/dashboard/user/create-user';
import EditUserPage from 'pages/dashboard/user/edit-user';
import WarehousePage from 'pages/dashboard/warehouse';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from 'shared/components/require-auth';

/**
 * The styles goes here.
 */
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to='/d' replace={true} />} />
        <Route path='/auth' element={<AuthLayout />}>
          <Route path='/auth/login' element={<LoginPage />} />
        </Route>
        <Route
          path='/d'
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route path='/d/purchase-orders/:id' element={<PreorderDetail />} />
          <Route
            path='/d/purchase-orders/create'
            element={<CreatePurchaseOrderPage />}
          />
          <Route
            path='/d/purchase-orders/:id/edit'
            element={<EditPurchaseOrderPage />}
          />
          <Route path='/d/purchase-orders' element={<PurchaseOrderPage />} />
          <Route path='/d/inbounds/stock' element={<InboundStockPage />} />
          <Route path='/d/inbounds' element={<InboundPage />} />
          <Route path='/d/warehouses' element={<WarehousePage />} />
          <Route path='/d/product-types' element={<ProductTypesPage />} />
          <Route path='/d/brand-suplier/create' element={<CreateBrandPage />} />
          <Route path='/d/brand-suplier' element={<BrandSuplierIndexPage />} />

          <Route path='/d/reports/purchase-orders' element={<ReportPurchaseOrder />} />

          <Route path='/d/products/:id' element={<ProductStockDetailPage />} />
          <Route path='/d/products/manage' element={<ProductsManagePage />} />
          <Route path='/d/products' element={<ProductsPage />} />
          <Route path='/d/users/:id' element={<EditUserPage />} />
          <Route path='/d/users' element={<UserPage />} />
          <Route path='/d/users/create' element={<CreateUserPage />} />
          <Route path='/d/roles' element={<RolesPage />} />
          <Route path='/d/roles/:id' element={<EditRolePage />} />
          <Route path='/d/roles/create' element={<CreateRolePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

type Props = {
  purchaseOrder: any;
};

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#E4E4E4',
  },
  text: {
    fontSize: 10,
    textAlign: 'left',
    marginBottom: 5,
  },
  section: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    marginBottom: 20,
    justifyContent: 'space-between',
    width: '100%',
  },
  tableCell: {
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    padding: 5,
    alignItems: 'center',
  },
  tableBody: {
    fontSize: 10,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// Create Document Component
const MyDocument = (props: Props) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={{ textAlign:"center" }}>
        <Text style={{ fontSize:"14px", fontWeight:"bold" }}>PT. TJS USAHA DIGITAL</Text>
        <Text style={{ fontSize:"10px", marginBottom:"1px" }}>Gedung Centennial Tower Lt.23 Unit C, Jl Jendral Gatot Subroto Kav 24-25, Karet Semanggi Setiabudi Jakarta Selatan</Text>
        <Text style={{ fontSize:"10px", marginBottom:"1px" }}>Tel : (021) 2295 8270 Fax : (021) 2295 8279</Text>
        <Text style={{ fontSize:"10px", marginBottom:"1px" }}>NPWP : 86.995.459.4-063.000</Text>

        <Text style={{ fontSize:"16px", fontWeight:"black", marginTop:"8px", textDecoration:"underline" }}>Settlement</Text>
        <Text style={{ fontSize:"10px", marginBottom:"16px" }}>No. : {props.purchaseOrder.number}</Text>
      </View>
      <View style={{ ...styles.section, justifyContent:"space-between", display:"flex", alignItems:"stretch" }}>
        <View style={{ width: '48%'}}>
          <Text style={{ fontSize:"10px" }}>From :</Text>
          <View style={{ border:"1px solid black", padding:'12px', height:"80px" }}>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>{props.purchaseOrder.supplier_name}</Text>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>Alamat : {props.purchaseOrder.supplier_address}</Text>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>No. Rekening  : {props.purchaseOrder.supplier_bank_account_number} ({props.purchaseOrder.supplier_bank_name})</Text>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>A/N Rekening  : {props.purchaseOrder.supplier_name}</Text>
          </View>
        </View>
        <View style={{ width: '48%'}}>
          <Text style={{ fontSize:"10px" }}>Deliver To :</Text>
          <View style={{ border:"1px solid black", padding:'12px', height:"80px" }}>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>{props.purchaseOrder.wh_name} ({props.purchaseOrder.wh_area})</Text>
            <Text style={{ fontSize:"10px", fontWeight:"black" }}>{props.purchaseOrder.wh_address}</Text>
          </View>
        </View>
      </View>
      {/* <View style={styles.section}>
        <div>
          <Text style={styles.text}>Nama User</Text>
          <Text style={styles.text}>{props.purchaseOrder.created_by_name}</Text>
        </div>

        <div>
          <Text style={styles.text}>ID Settlement</Text>
          <Text style={styles.text}>{props.purchaseOrder.number}</Text>
        </div>

        <div>
          <Text style={styles.text}>Status</Text>
          <Text style={styles.text}>{props.purchaseOrder.status_label}</Text>
        </div>
        <div>
          <Text style={styles.text}>Nomor Handphone</Text>
          <Text style={styles.text}>
            {props.purchaseOrder.created_by_phone || '-'}
          </Text>
        </div>
        <div>
          <Text style={styles.text}>Tanggal pengajuan PO</Text>
          <Text style={styles.text}>
            {props.purchaseOrder.created_at || '-'}
          </Text>
        </div>
        <div>
          <Text style={styles.text}>Tanggal PO dibayar</Text>
          <Text style={styles.text}>{props.purchaseOrder.paid_at || '-'}</Text>
        </div>
      </View> */}

      <Table data={[{}]}>
        <TableHeader>
          <TableCell style={styles.tableCell}>Order Date :</TableCell>
          <TableCell style={styles.tableCell}>Schedule Time :</TableCell>
          <TableCell style={styles.tableCell}>Project :</TableCell>
          <TableCell style={styles.tableCell}>No. SRP</TableCell>
          <TableCell style={styles.tableCell}>SPK</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>{dayjs(props.purchaseOrder.created_at).format("D-MMM-YY")}</Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>{dayjs(props.purchaseOrder.created_at).format("D-MMM-YY")}</Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>&nbsp;</Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>&nbsp;</Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>&nbsp;</Text>
            )}
          />
        </TableBody>
      </Table>
      <Text>&nbsp;</Text>

      <Table data={(props.purchaseOrder.Product||[]).map((r: any,i: number)=>({...r, key:i+1}))}>
        <TableHeader>
          <TableCell style={styles.tableCell}>No</TableCell>
          <TableCell style={styles.tableCell}>Description</TableCell>
          <TableCell style={styles.tableCell}>Quantity</TableCell>
          <TableCell style={styles.tableCell}>Unit Price</TableCell>
          <TableCell style={styles.tableCell}>Amount</TableCell>
        </TableHeader>
        <TableBody>
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>
                {r.key}
              </Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>
                {r.product_name}
              </Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>{r.qty_alocation.toLocaleString()} {r.satuan}</Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>
                Rp {r.unit_price_real.toLocaleString()}
              </Text>
            )}
          />
          <DataTableCell
            style={styles.tableBody}
            getContent={(r) => (
              <Text style={styles.tableBody}>
                Rp {r.sub_total.toLocaleString()}
              </Text>
            )}
          />
        </TableBody>
      </Table>
      <View style={{ textAlign:'right', width:'100%', border:'1px solid black', borderTop:'none', padding:'5px' }}>
          <Text style={{ fontSize:'10px' }}>Grand Total : Rp {(props.purchaseOrder.grand_total||"").toLocaleString()}</Text>
      </View>

      <Text>&nbsp;</Text>
      <View style={{ ...styles.section, justifyContent:"space-between", display:"flex", alignItems:"stretch" }}>
        <View style={{ width:"65%" }}>
        <Table data={[""]}>
          <TableHeader>
            <TableCell style={styles.tableCell}>Prepared By</TableCell>
            <TableCell style={styles.tableCell}>Checked By,</TableCell>
            <TableCell style={styles.tableCell}>Checked By,</TableCell>
            <TableCell style={styles.tableCell}>Approved By,</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              style={{ ...styles.tableBody }}
              getContent={(r) => (
                <Text style={{ paddingTop:"30px" }}>
                  Dani - Regional Lead
                </Text>
              )}
            />
            <DataTableCell
              style={{ ...styles.tableBody }}
              getContent={(r) => (
                <Text style={{ paddingTop:"30px" }}>
                  Firza Ibrahim - Ops
                </Text>
              )}
            />
            <DataTableCell
              style={{ ...styles.tableBody }}
              getContent={(r) => (
                <Text style={{ paddingTop:"30px" }}>
                  Yuliati - Finance
                </Text>
              )}
            />
            <DataTableCell
              style={{ ...styles.tableBody }}
              getContent={(r) => (
                <Text style={{ paddingTop:"30px" }}>
                  Putri Noor Shaqina - CMO
                </Text>
              )}
            />
          </TableBody>
        </Table>
        </View>

        <View style={{ width:"30%" }}>
          <Table data={[""]}>
            <TableHeader>
              <TableCell style={styles.tableCell}>Confirmed by Supplier</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={{ ...styles.tableBody }}
                getContent={(r) => (
                  <View style={{ height:"40px", textAlign:"left", width:"100%"}}>
                    <Text>
                      Date :
                    </Text>
                    <Text>
                      Name :
                    </Text>
                  </View>
                )}
              />
            </TableBody>
          </Table>
        </View>
      </View>
      <Text>&nbsp;</Text>
      <View style={{ ...styles.section }}>
        <Text style={{ fontWeight:800, fontSize:"10px" }}>Payment Terms : {props.purchaseOrder.supplier_payment}</Text>
      </View>
      <Text style={{ fontSize:"8px", marginTop:"14px", fontStyle:"italic"  }}><i>Notes - Supplier please sign and fax this Purhcase Order to us as Confirmation</i></Text>
    </Page>
  </Document>
);

export default MyDocument;

import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
} from 'antd';
import { LabeledValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchProductCommand } from 'shared/commands/product.command';
import purchaseOrderCommand from 'shared/commands/purchase-order.command';
import { useListPurchaseOrderQuery } from 'shared/queries/purchase-order/purchase-order.query';
import { useDetailSupplierQuery, useListSupplierQuery } from 'shared/queries/supplier/supplier.query';
import { useListWarehouseQuery } from 'shared/queries/warehouse/warehouse.query';

const { Option } = Select;

const ProductList = (props: {
  onChange: (value: LabeledValue, option: any) => void;
  name: any;
  fieldKey: any;
  supplier_id: any;
}) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [keywords, setKeywords] = React.useState("");

  const handleSearch = (value: any) => {
    // console.log(value);
    setKeywords(value);
  };

  useEffect(() => {
    searchProductCommand({
      // TODO: enable this again later
      // status:PurchaseOrderStatus.Finish
      supplier_id: 0, //props.supplier_id,
      keyword:keywords
    })
      .then((response) => {
        const products = (response.data.data.list || [])
          .map((product) => {
            return product.variant.map((variant) => {
              const label =
                product.name +
                ', ' +
                variant.satuan +
                ', (' +
                variant.sku +
                ')';
              return { label: label, value: variant.sku };
            });
          })
          .flat();

        setOptions(products);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [props.supplier_id, keywords]);

  return (
    <Form.Item
      rules={[{ required: true, message: 'Produk wajib diisi' }]}
      label='Pilih Produk'
      name={[props.name, 'products']}
      fieldKey={[props.fieldKey, 'products']}
      required
    >
      <Select
        showSearch
        labelInValue
        filterOption={false}
        placeholder='Pilih Produk'
        notFoundContent={fetching ? <Spin size='small' /> : null}
        options={options}
        onSearch={handleSearch}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};

function CreatePurchaseOrderPage() {
  const navigate = useNavigate();
  const [supplierID, setSupplierID] = useState<number>();
  const [onSending, setOnSending] = useState(false);
  const [productIdx, setProductIdx] = useState<number>(0);
  const [warehouseKeywords, setWarehouseKeywords] = useState('');
  const [supplierKeywords, setSupplierKeywords] = useState('');

  const purhcaseOrderQuery = useListPurchaseOrderQuery();
  const warehouseQuery = useListWarehouseQuery({keyword:warehouseKeywords});
  const { data: supplierList, isLoading: isSupplierFetching } = useListSupplierQuery(1, {keyword:supplierKeywords});
  const { data: supplierDetail, isRefetching } = useDetailSupplierQuery(supplierID || 0);

  const [unit, setUnit] = useState<any>();

  const [supplier, setSupplier] = useState({ id: 0, phone: '', accountNo: '', bankName: '', paymentBrandowner:'' });

  const handleWarehouseSearch = (value:any)=>{
    setWarehouseKeywords(value);
  }

  const handleSupplierSearch = (value:any)=>{
    setSupplierKeywords(value);
  }

  const onProductChange = (value: LabeledValue) => {
    const unit = value.label?.toString().split(', ')[1];
    setUnit(unit);
  };

  // const onSupplierChange = (value: any, options: any) => {
  //   listSupplierCommand;

  //   const selectedSupplier = supplierList?.data.data.list.find(
  //     (item) => item.id == value.value
  //   );
  //   // console.log(selectedSupplier);
  //   setSupplier({
  //     id: value.value,
  //     accountNo: selectedSupplier?.bank_account_number || '',
  //     phone: selectedSupplier?.phone || '',
  //   });

  //   // console.log();
  // };

  const onFinish = (values: any) => {
    const products: any = [];

    (values.products || []).forEach((product: any) => {
      const unit = product.products.label?.toString().split(', ')[1];
      products.push({
        product_name: product.name || '',
        product_sku: product.products.value,
        product_satuan: unit,
        product_buy_price: parseInt(product.buying_price) || 0,
        product_buy_qty: parseInt(product.qty) || 0,
      });
    });

    setOnSending(true);

    purchaseOrderCommand
      .createPurchaseOrderRequest({
        supplier_name: values.supplier_name.label,
        supplier_phone: supplier.phone,
        supplier_bank_name: supplier.bankName,
        supplier_bank_account_number: supplier.accountNo,
        brandowner_id: supplier.id,
        warehouse_id: values.warehouse.value,
        product: products,
        note: values.notes,
      })
      .then(() => {
        navigate('/d/purchase-orders');
        purhcaseOrderQuery.refetch();
      })
      .catch((error) => {
        message.error(
          error.response.data.message || 'Tidak dapat menyimpan PO'
        );
      })
      .finally(() => {
        setOnSending(false);
      });
  };

  const handleAutoComplete = (e: any) => {
    const { value } = e;
    setSupplierID(value);
  }

  useEffect(() => {
    if (!isRefetching) {
      setSupplier({
        id: supplierID || 0,
        phone: supplierDetail?.data.data.phone,
        accountNo: supplierDetail?.data.data.bank_account_number,
        bankName: supplierDetail?.data.data.bank_name,
        paymentBrandowner: supplierDetail?.data.data.payment_brandowner
      })
    }

  }, [supplierID, supplierDetail]);

  return (
    <>
      <Form
        name='dynamic_form_nest_item'
        autoComplete='off'
        layout='vertical'
        onFinish={onFinish}
      >
        <Card title='Supplier' style={{ marginBottom: '2em' }}>
          <Row gutter={52}>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                rules={[{ required: true, message: 'Supplier harus diisi' }]}
                label='Nama Supplier'
                name='supplier_name'
                required
              >
                <Select
                  labelInValue
                  placeholder='Ketikan Nama Supplier'
                  onChange={handleAutoComplete}
                  showSearch
                  filterOption={false}
                  notFoundContent={isSupplierFetching ? <Spin size='small' /> : null}
                  onSearch={handleSupplierSearch}
                // options={}
                // notFoundContent={fetching ? <Spin size='small' /> : null}
                // options={options}
                // onSearch={handleSearch}
                // onChange={props.onChange}
                >
                  {(supplierList?.data.data.list||[]).map((warehouse: any) => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='supplier_phone'
                label='No. Telepon'
                initialValue={supplier.phone}
              >
                <Input disabled value={supplier.phone} placeholder={supplier.phone} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='supplier_account_number'
                label='No. Rekening'
                initialValue={supplier.accountNo}
              >
                <Input
                  disabled
                  placeholder={supplier.accountNo}
                  value={supplier.accountNo}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='supplier_account_number'
                label='Tipe Pembayaran'
                initialValue={supplier.paymentBrandowner}
              >
                <Input
                  disabled
                  placeholder={supplier.paymentBrandowner}
                  value={supplier.paymentBrandowner}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='supplier_bank'
                label='Bank'
              >
                <Input
                  disabled
                  placeholder={supplier.bankName}
                  value={supplier.bankName}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='warehouse'
                label='Gudang'
                rules={[{ required: true, message: 'Gudang harus diisi' }]}
              >
                <Select
                  labelInValue
                  placeholder='Ketikan Nama Gudang'
                  showSearch
                  filterOption={false}
                  notFoundContent={warehouseQuery.isFetching ? <Spin size='small' /> : null}
                  onSearch={handleWarehouseSearch}
                >
                  {(warehouseQuery.data?.data.data.list||[]).map((warehouse: any) => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <Form.Item
                name='notes'
                label='Catatan'
              >
                <Input
                  placeholder='Masukkan Catatan'
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <div style={{ marginTop: '1rem' }}>
          <Form.List name='products'>
            {(fields, { add, remove }) => (
              <div>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Card
                      key={key}
                      title='Produk'
                      style={{ marginBottom: '2em' }}
                    >
                      <Row gutter={100}>
                        <Col xs={24} xl={10} span={8}>
                          <h1>Produk {name + 1}</h1>
                          <div style={{ marginTop: '1rem' }}>
                            <ProductList
                              onChange={onProductChange}
                              name={name}
                              fieldKey={fieldKey}
                              supplier_id={supplier.id}
                            />

                            <Row gutter={60} justify='space-between'>
                              <Col xs={24} xl={10} span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'buying_price']}
                                  fieldKey={[fieldKey, 'buying_price']}
                                  label='Harga Beli (satuan)'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Harga Beli harus diisi',
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    addonBefore='Rp'
                                    formatter={(value: any) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                      )
                                    }
                                    parser={(value: any) =>
                                      value.replace(/\$\s?|(,*)/g, '')
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={12} span={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'qty']}
                                  fieldKey={[fieldKey, 'qty']}
                                  label='Kuantitas Pembelian'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Kuantitas harus diisi',
                                    },
                                    { type: 'string', min: 1 },
                                  ]}
                                >
                                  <Input type='number' placeholder='Kuantitas Pembelian' />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Button type='primary' htmlType='submit' onClick={() => remove(name)}>
                              Hapus
                            </Button>
                          </div>
                        </Col>
                        {/* <Col span={12}>
                        <h1>Distribusi Stok</h1>
                        <div style={{ marginTop: '1rem' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'ai']}
                            fieldKey={[fieldKey, 'ai']}
                            label='Harga beli (satuan)'
                            rules={[{ required: true }]}
                          >
                            <Select placeholder='Pilih satuan'>
                              <Option value='china'>China</Option>
                              <Option value='usa'>U.S.A</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'ar']}
                            fieldKey={[fieldKey, 'ar']}
                            label='Stok di gudang'
                            rules={[
                              { required: true },
                              { type: 'string', min: 6 },
                            ]}
                          >
                            <Input placeholder='Jumlah stok di gudang' />
                          </Form.Item>
                        </div>
                      </Col> */}
                      </Row>
                    </Card>
                  )
                }
                )}
                <Row>
                  <Col xs={24} xl={4} span={8}>
                    <Form.Item>
                      <Button type='primary' onClick={() => add()} block>
                        Tambah Produk
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Form.List>
          {/* <Form.Item>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item> */}
          <Affix offsetBottom={0}>
            <Card style={{ textAlign: 'right' }}>
              <Button type='primary' htmlType='submit' loading={onSending}>
                Simpan
              </Button>
            </Card>
          </Affix>
        </div>
      </Form>
    </>
  );
}

export default CreatePurchaseOrderPage;

import { useQuery } from 'react-query';
import { listAreaWarehouseCommand, listWarehouseCommand, WarehouseListRequest } from 'shared/commands/warehouse.command';

export const useListWarehouseQuery = (props?: WarehouseListRequest) => {
  const queries = useQuery(['warehouses', props],
    async () => {
      const response = await listWarehouseCommand({ limit: 10, page: 1, ...props });
      return response;
    });
  return queries;
};


export const useListAreaWarehouseQuery = () => {
  const queries = useQuery(['warehouses area'],
    async () => {
      const response = await listAreaWarehouseCommand();
      return response;
    });
  return queries;
};

import {
  Affix,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { upperFirst } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import inboundCommand from 'shared/commands/inbound.command';
import {
  detailPurchaseOrderCommand,
  listPurchaseOrderCommand,
} from 'shared/commands/purchase-order.command';
import { useListWarehouseQuery } from 'shared/queries/warehouse/warehouse.query';

const { Text } = Typography;
const { Option } = Select;

export const ProductCard = (props: { product: any; sequence: number }) => {
  const warehouseQuery = useListWarehouseQuery({ status: 1 });

  return (
    <Card title={`Produk ${props.sequence}`} style={{ marginBottom: '2em' }}>
      <Card.Grid hoverable={false} style={{ width: '100%', height: '100%' }}>
        {/* <Form.Item
          label='Pilih Gudang'
          name={['products', props.product.sku, 'warehouse']}
        >
          <Select placeholder='Pilih Gudang'>
            {(warehouseQuery.data?.data.data.list || []).map((warehouse) => (
              <Option value={warehouse.id}>{warehouse.name}</Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          label='Stok masuk gudang'
          name={['products', props.product.sku, 'inbound_stock']}
        >
          <InputNumber
            placeholder='100'
            max={props.product.qty_alocation - props.product.qty_real}
          />
        </Form.Item>
      </Card.Grid>
      <Card.Grid hoverable={false} style={{ width: '100%', height: '100%' }}>
        <Form.Item
          rules={[
            { required: true, message: 'Nama Produk tidak boleh kosong' },
          ]}
          label='Nama Produk'
          required
        >
          <Input disabled value={props.product.product_name} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Harga beli tidak boleh kosong' }]}
          label='Harga beli (satuan)'
          required
        >
          <Input
            disabled
            value={`Rp. ${props.product.unit_price_real.toLocaleString('id')}`}
          />
        </Form.Item>
        <Row gutter={32}>
          <Col xs={24} xl={10} span={8}>
            <Form.Item
              rules={[
                { required: true, message: 'Kuantitas tidak boleh kosong' },
              ]}
              label='Kuantitas'
              required
            >
              <Input disabled value={props.product.qty_alocation} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              rules={[{ required: true, message: 'Satuan tidak boleh kosong' }]}
              label='Satuan'
              required
            >
              <Select
                disabled
                placeholder='Satuan'
                value={props.product.satuan}
              >
                <Option value={props.product.satuan}>
                  {upperFirst(props.product.satuan)}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card.Grid>
    </Card>
    //    <Form.Item rules={[{ required: true }]} label='Harga Jual' required>
    //    <Input placeholder='Rp 41.000' />
    //  </Form.Item>
  );
};

export const PurchaseOrderInput = (props: {
  onChange: (value: LabeledValue, option: any) => void;
}) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);

  useEffect(() => {
    listPurchaseOrderCommand({
      // TODO: enable this again later
      // status:PurchaseOrderStatus.Finish
    })
      .then((response) => {
        const mappedPOItems = (response.data.data.list || []).map(
          (purchaseOrderItem) => {
            return {
              label: purchaseOrderItem.number,
              value: purchaseOrderItem.id,
            };
          }
        );

        setOptions(mappedPOItems);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  return (
    <Form.Item
      rules={[{ required: true }]}
      label='Pilih Purchase Order'
      name='po'
      required
    >
      <Select
        labelInValue
        filterOption={false}
        placeholder='Pilih PO yang sudah selesai'
        notFoundContent={fetching ? <Spin size='small' /> : null}
        options={options}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};

function InboundStockPage() {
  const navigate = useNavigate();
  const [onSending, setOnSending] = useState(false);
  const [products, setProducts] = useState<any[]>([]);

  const onPurchaseOrderNumberChange = (value: LabeledValue) => {
    detailPurchaseOrderCommand(Number(value.value)).then((response) => {
      setProducts(response.data.data.row.Product || []);
    });
  };

  const onFinish = (values: any) => {
    const products: any = [];
    const productIds = Object.keys(values.products);

    productIds.forEach((productSku) => {
      products.push({
        sku: productSku,
        qty_arrival: values.products[productSku].inbound_stock,
        qty_qc: values.products[productSku].inbound_stock,
        warehouse_id: values.products[productSku].warehouse,
        note: values.note || '',
      });
    });

    setOnSending(true);

    inboundCommand
      .createInboundCommand({
        po_id: values.po.value,
        qc_datetime: '',
        transaction_datetime: '2021-12-25 14:23:42',
        products: products,
      })
      .then(() => {
        navigate('/d/inbounds');
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {
        setOnSending(false);
      });
  };

  return (
    <>
      <Form onFinish={onFinish}>
        <Card title='Purchase Order' style={{ marginBottom: '2em' }}>
          <Row gutter={32}>
            <Col flex={1}>
              <PurchaseOrderInput onChange={onPurchaseOrderNumberChange} />
            </Col>
            <Col flex={3}>
              <Form.Item
                rules={[{ required: true }]}
                label='Catatan'
                required
                name='note'
              >
                <Input.TextArea rows={4} placeholder='Tambah Catatan' />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            sequence={index + 1}
            product={product}
          />
        ))}
        <Affix offsetBottom={0}>
          <Card style={{ textAlign: 'right' }}>
            <Button type='primary' htmlType='submit' loading={onSending}>
              Simpan
            </Button>
          </Card>
        </Affix>
      </Form>
    </>
  );
}

export default InboundStockPage;

import { wrapApi } from "shared/apis";
import api, { ApiResponse } from "shared/apis/api";

export const listOutboundBySku = <T>(sku:any, page:number, sort?: string, dir?: string) => {
    return api<T, ApiResponse<any>>(
        'GET',
        wrapApi('/outbound/v1/list',{page, sku, limit:99999999999, sort, dir}),
    );
};

export const listOutbound = <T>(filters:any) => {
    return api<T, ApiResponse<any>>(
        'GET',
        wrapApi('/outbound/v1/list', filters),
    );
};
import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
} from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { searchProductCommand } from 'shared/commands/product.command';
import { editPurchaseOrderRequest } from 'shared/commands/purchase-order.command';
import { useDetailPurchaseOrderQuery } from 'shared/queries/purchase-order/purchase-order.query';
import {
  useDetailSupplierQuery,
  useListSupplierQuery,
} from 'shared/queries/supplier/supplier.query';

const { Option } = Select;

const ProductList = (props: {
  onChange: (value: LabeledValue, option: any) => void;
  name: any;
  fieldKey: any;
  supplier_id: any;
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [keywords, setKeywords] = useState('');

  const handleSearch = (value: any) => {
    // console.log(value);
    setKeywords(value);
  };

  useEffect(() => {
    searchProductCommand({
      // TODO: enable this again later
      // status:PurchaseOrderStatus.Finish
      supplier_id: 0, //props.supplier_id,
      keyword: keywords,
    })
      .then((response) => {
        const products = (response.data.data.list || [])
          .map((product) => {
            return product.variant.map((variant) => {
              const label =
                product.name +
                ', ' +
                variant.satuan +
                ', (' +
                variant.sku +
                ')';
              return { label: label, value: variant.sku };
            });
          })
          .flat();

        setOptions(products);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [props.supplier_id, keywords]);

  return (
    <Form.Item
      rules={[{ required: true, message: 'Produk wajib diisi' }]}
      label='Pilih Produk'
      name={[props.name, 'products']}
      fieldKey={[props.fieldKey, 'products']}
      required
    >
      <Select
        showSearch
        labelInValue
        filterOption={false}
        placeholder='Pilih Produk'
        notFoundContent={fetching ? <Spin size='small' /> : null}
        options={options}
        onSearch={handleSearch}
        onChange={props.onChange}
      />
    </Form.Item>
  );
};

function EditPurchaseOrderPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const POQuery = useDetailPurchaseOrderQuery(Number(id));
  const [supplierID, setSupplierID] = useState<number>();
  const [supplierKeywords, setSupplierKeywords] = useState('');
  const { data: supplierList, isLoading: isSupplierFetching } =
    useListSupplierQuery(1, { keyword: supplierKeywords });
  const [supplier, setSupplier] = useState({
    id: 0,
    name:'',
    phone: '',
    accountNo: '',
    bankName: '',
    paymentBrandowner: '',
  });
  const supplierQuery = useDetailSupplierQuery(supplierID || 0);

  const handleSupplierSearch = (value: any) => {
    setSupplierKeywords(value);
  };

  const onFinish = (values: any) => {
    const products: any = [];

    values.product.forEach((product: any) => {
      products.push({
        product_sku: product.product_sku,
        product_satuan: product.product_satuan,
        product_buy_price: parseInt(product.product_buy_price) || 0,
        product_buy_qty: parseInt(product.product_buy_qty) || 0,
      });
    });

    editPurchaseOrderRequest(Number(id), {
      product: products,
      supplier_name:  supplier.name,
      supplier_phone: supplier.phone,
      supplier_bank_name: supplier.bankName,
      supplier_bank_account_number: supplier.accountNo,
      brandowner_id: supplier.id,
      warehouse_id: POQuery.data?.data.data.row.warehouse_id,
      note: values.notes || '',
    })
      .then(() => {
        setLoading(false);
        POQuery.refetch();
        message.success('Berhasil mengubah data');
      })
      .catch((error) => {
        message.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if (!supplierQuery.isRefetching) {
      setSupplier({
        id: supplierID || 0,
        name: supplierQuery.data?.data.data.name,
        phone: supplierQuery.data?.data.data.phone,
        accountNo: supplierQuery.data?.data.data.bank_account_number,
        bankName: supplierQuery.data?.data.data.bank_name,
        paymentBrandowner: supplierQuery.data?.data.data.payment_brandowner,
      });
    }
  }, [supplierID, supplierQuery.data]);

  useEffect(() => {
    if (POQuery.isSuccess && POQuery.isFetched) {
      setSupplierID(POQuery.data.data.data.row.supplier_id as any);
    }
  }, [POQuery.isFetching]);

  const handleAutoComplete = (e: any) => {
    setSupplierID(e);
  };

  if (!POQuery.isSuccess && !supplierQuery.isSuccess) return <Spin />;

  return (
    <>
      <Form
        layout='vertical'
        name='dynamic_form_nest_item'
        onFinish={onFinish}
        autoComplete='off'
      >
        <>
          <Card title='Supplier' style={{ marginBottom: '2em' }}>
            <Row gutter={52}>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  rules={[{ required: true, message: 'Supplier harus diisi' }]}
                  label='Nama Supplier'
                  name='supplier_name'
                  required
                  initialValue={POQuery.data?.data.data.row.supplier_id}
                >
                  <Select
                    placeholder={POQuery.data?.data.data.row.supplier_name}
                    onChange={handleAutoComplete}
                    showSearch
                    filterOption={false}
                    notFoundContent={
                      isSupplierFetching ? <Spin size='small' /> : null
                    }
                    onSearch={handleSupplierSearch}
                  >
                    {(supplierList?.data.data.list || []).map(
                      (supplier_list: any) => (
                        <Option
                          key={supplier_list.id}
                          value={parseInt(supplier_list.id)}
                        >
                          {supplier_list.name}
                        </Option>
                      )
                    )}
                    <Option
                      key={POQuery.data?.data.data.row.supplier_id}
                      value={parseInt(
                        POQuery.data?.data.data.row.supplier_id as any
                      )}
                    >
                      {POQuery.data?.data.data.row.supplier_name}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_phone'
                  label='No. Telepon'
                  initialValue={supplier.phone}
                >
                  <Input
                    disabled
                    value={supplier.phone}
                    placeholder={supplier.phone}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_account_number'
                  label='No. Rekening'
                  initialValue={supplier.accountNo}
                >
                  <Input
                    disabled
                    placeholder={supplier.accountNo}
                    value={supplier.accountNo}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_type'
                  label='Tipe Pembayaran'
                  initialValue={supplier.paymentBrandowner}
                >
                  <Input
                    disabled
                    placeholder={supplier.paymentBrandowner}
                    value={supplier.paymentBrandowner}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item name='supplier_bank' label='Bank'>
                  <Input
                    disabled
                    placeholder={supplier.bankName}
                    value={supplier.bankName}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='notes'
                  label='Catatan'
                  initialValue={POQuery.data?.data.data.row.po_note}
                >
                  <Input placeholder='Masukkan Catatan' />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          {/* <Card title='Supplier'>
            <Row gutter={52}>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_name'
                  initialValue={data?.data.data.row.supplier_name}
                  label='Nama Supplier'
                  rules={[
                    { required: true, message: 'Nama Supplier harus diisi' },
                    // { type: "url", warningOnly: true },
                  ]}
                >
                  <Input placeholder='Nama Supplier' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_phone'
                  initialValue={data?.data.data.row.supplier_phone}
                  label='No. telepon'
                  rules={[
                    { required: true, message: 'No. telepon harus diisi' },
                    // { type: "url", warningOnly: true },
                  ]}
                >
                  <Input placeholder='No. telepon' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_bank_account_number'
                  label='No. Rekening'
                  initialValue={
                    data?.data.data.row.supplier_bank_account_number
                  }
                  rules={[
                    { required: true, message: 'No. Rekening harus diisi' },
                    // { type: "url", warningOnly: true },
                  ]}
                >
                  <Input placeholder='No. rekening' />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <Form.Item
                  name='supplier_bank_name'
                  initialValue={data?.data.data.row.supplier_bank_name}
                  label='Bank'
                  rules={[
                    { required: true, message: 'Bank harus diisi' },
                    // { type: "url", warningOnly: true },
                  ]}
                >
                  <Select placeholder='Pilih Bank'>
                    <Option value='BCA'>BCA</Option>
                    <Option value='MANDIRI'>MANDIRI</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card> */}
          <div style={{ marginTop: '1rem' }}>
            <Form.List
              name='product'
              initialValue={POQuery.data?.data.data.row.Product.map(
                (item: any) => {
                  return {
                    product_buy_price: item.unit_price_real,
                    product_sku: item.sku,
                    product_satuan: item.satuan,
                    product_buy_qty: item.qty_alocation,
                  };
                }
              )}
            >
              {(fields) => (
                <>
                  {fields.map((field) => (
                    <Card title={`Produk ${field.key + 1}`}>
                      <Row gutter={100} key={field.key}>
                        <Col xs={24} xl={8} span={8}>
                          <div style={{ marginTop: '1rem' }}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'product_sku']}
                              label='Produk SKU'
                              rules={[
                                {
                                  required: true,
                                  message: 'Produk SKU harus diisi',
                                },
                              ]}
                            >
                              <Input placeholder='Produk SKU' />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'product_satuan']}
                              label='Produk Satuan'
                              rules={[
                                {
                                  required: true,
                                  message: 'Produk Satuan harus diisi',
                                },
                              ]}
                            >
                              <Input placeholder='Produk Satuan' />
                            </Form.Item>
                            <Row gutter={60} justify='space-between'>
                              <Col xs={24} xl={12} span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'product_buy_price']}
                                  label='Harga beli'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Harga beli harus diisi',
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    addonBefore='Rp'
                                    controls={false}
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                      )
                                    }
                                    disabled
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} xl={10} span={8}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'product_buy_qty']}
                                  label='Jumlah Qty'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Jumlah Qty harus diisi',
                                    },
                                  ]}
                                >
                                  <Input placeholder='qty' disabled />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </>
              )}
            </Form.List>
          </div>
        </>

        <Affix offsetBottom={0}>
          <Card>
            <Form.Item>
              <Button loading={loading} type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Card>
        </Affix>
      </Form>
    </>
  );
}

export default EditPurchaseOrderPage;

import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

export type ProductSearchRequest = {
  keyword?: number;
  limit?: number;
  supplier_id?: number;
};

export interface ProductSearchResponse {
  name: string;
  variant: Variant[];
}

export interface Variant {
  sku: string;
  satuan: string;
}

export interface Datatable {
  list: ProductSearchResponse[];
}

export const searchProductCommand = <T = ProductSearchRequest>(data: T) => {
  const tempData = data as any;

  const requestData = {
    ...tempData,
  };

  return api<T, ApiResponse<Datatable>>(
    'GET',
    wrapApi('/product/v1/search', requestData)
  );
};

export type ProductStockRequest = {
  limit?: number;
  page?: number;
};


export interface List {
  sku: string;
  product_name: string;
  qty: number;
  satuan: string;
}
export interface Data {
  limit: number;
  list: List[];
  page: number;
  total: number;
  total_page: number;
}

export interface RootObject {
  status: number;
  message: string;
  data: Data;
}


export const stockProductCommand = <T = ProductSearchRequest>(data: T) => {
  const tempData = data as any;

  const requestData = {
    ...tempData,
  };

  return api<T, ApiResponse<Data>>(
    'GET',
    wrapApi('/product/v1/list', requestData)
  );
}

export const stockProductDetailCommand = (sku: string) => {
  return api(
    'GET',
    wrapApi(`/product/v1/check_multiple?sku=${sku}`)
  );
};

export default {
  searchProductCommand,
  // put the rest export in here
};

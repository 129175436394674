const TokenBroker = {
  put: (token:string) => localStorage.setItem('po-auth-token', token),
  get: () => localStorage.getItem('po-auth-token'),
  clear: () => localStorage.removeItem('po-auth-token'),
};

export const RoleBroker = {
  put: (token:any) => localStorage.setItem('po-role-token', token),
  get: () => localStorage.getItem('po-role-token'),
  clear: () => localStorage.removeItem('po-role-token'),
};

export const UserBroker = {
  put: (token:any) => localStorage.setItem('po-user-token', token),
  get: () => localStorage.getItem('po-user-token'),
  clear: () => localStorage.removeItem('po-user-token'),
};

export default TokenBroker;

import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

// export enum PurchaseOrderStatus {
//   New = 0,
//   Approve = 1,
//   Paid = 2,
//   Finish = 3,
//   Close = 8,
//   Reject = 9
// }

// export type PurchaseOrderListRequest = {
//   page?: number;
//   limit?: number;
//   start_date?: string;
//   end_date?: string;
//   sort_by?: string;
//   dir?: string;
//   status?: PurchaseOrderStatus;
// };

export interface Po {
  id: number;
  number: string;
}

export interface Product {
  pod_id: number;
  name: string;
  sku: string;
  unit_type: string;
  price_estimation: number;
  price_real: number;
  qty_allocation: number;
  qty_real: number;
}

export interface Supplier {
  id: number;
  name: string;
}

export interface InboundListResponse {
  id: number;
  po: Po;
  supplier: Supplier;
  warehouse: Supplier;
  product: Product;
  qty: number;
  qty_arrival: number;
  transaction_datetime: string;
  created_by: number;
  created_at: string;
  qc_by: number;
  qc_datetime: string;
  note: string;
  verified_by: number;
  is_verified: number;
}
export interface Datatable {
  limit: number;
  list: InboundListResponse[];
  page: number;
  total: number;
  total_page: number;
}

export type InboundRequestType = {
  page?: number;
  limit?: number;
  created_after?: string;
  created_before?: string;
  po_id?: string;
  suplier_id?: string;
  warehouse_id?: string;
  created_by?: string;
  qc_by?: string;
  sku?: string;
  transaction_date?: string;
  created_date?: string;
  qc_date?: string;
};

export const listInboundCommand = <T = InboundRequestType>(data: T) => {
  const tempData = data as any;

  const requestData = {
    ...tempData,
  };

  return api<T, ApiResponse<Datatable>>(
    'GET',
    wrapApi('/inbound/v1/list', requestData)
  );
};

type createInboundRequestType = {
  po_id: any;
  transaction_datetime: any;
  qc_datetime: any;
  products: {
    sku: any;
    qty_arrival: any;
    qty_qc: any;
    warehouse_id: any;
    note: any;
  }[];
};

const createInboundCommand = (data: createInboundRequestType) => {
  return api<any, ApiResponse<Datatable>>(
    'POST',
    wrapApi('/inbound/v1/create'),
    data
  );
};

export const inboundDetailCommand = <T = number|string>(id: T) => {
  return api<T, ApiResponse<InboundListResponse>>(
    'GET',
    wrapApi('/inbound/v1/detail/'+id)
  );
};

export default {
  listInboundCommand,
  createInboundCommand,
  inboundDetailCommand
  // put the rest export in here
};

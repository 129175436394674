import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Typography,
} from 'antd';
import { useState } from 'react';
import { createSupplierCommand } from 'shared/commands/supplier.command';
import { useListSupplierQuery } from 'shared/queries/supplier/supplier.query';

const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 30 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 14 },
    sm: { span: 10 },
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

function CreateBrandPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [modalSuccess, setModalSuccess] = useState<boolean>(false);
  const [form] = Form.useForm();
  const supplierQuery = useListSupplierQuery(1);

  const onFinish = (values: any) => {
    setLoading(true);
    createSupplierCommand(values)
      .then(() => {
        setLoading(false);
        supplierQuery.refetch();
        message.success('Data berhasil ditambahkan');
        form.resetFields();
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleSubmit = (values: any) => {
    setShowModalConfirm(false);
    setModalSuccess(true);
  };

  return (
    <>
      <Title level={3}>Buat Brand / Supplier</Title>
      <Card title='Form Description'>
        <Row justify='space-between' gutter={50}>
          <Col xs={24} xl={24} span={24} lg={24}>
            <Form
              style={{ marginTop: '2rem' }}
              {...formItemLayout}
              name='nest-messages'
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name='supplier_name'
                label='Nama Brand / Supplier'
                rules={[
                  {
                    required: true,
                    type: 'string',
                    min: 3,
                    message: 'Nama Brand Supplier 3 Karakter',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='supplier_phone'
                label='No. Telepon'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Gunakan Angka Untuk Nomor Telepon',
                  },
                  {
                    min: 9,
                    message: 'Minimal 9 digit',
                  },
                  {
                    max: 13,
                    message: 'Maksimal 13 digit',
                  },
                ]}
              >
                <Input addonBefore='+62' />
              </Form.Item>
              <Form.Item
                name='supplier_bank_name'
                label='Nama Bank'
                rules={[
                  { required: true },
                  { type: 'string', min: 3, message: 'Nama Bank 3 Karakter' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='supplier_bank_account_number'
                label='Nomor Rekening'
                rules={[
                  {
                    required: true,
                    pattern: new RegExp('^[0-9]*$'),
                    message: 'Gunakan Angka Untuk Nomor Rekening',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Button loading={loading} type='primary' htmlType='submit'>
                Simpan
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>

      {/* Modal Section */}
      <Modal
        closable={false}
        centered
        footer={null}
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        visible={showModalConfirm}
        width={300}
        onOk={() => setShowModalConfirm(false)}
        onCancel={() => () => setShowModalConfirm(false)}
      >
        <h3>Konfirmasi</h3>
        <p>Anda yakin ingin membuat Brand atau Supplier baru?</p>
        <Row gutter={18} justify='space-between'>
          <Col>
            <Button
              onClick={() => setShowModalConfirm(false)}
              type='primary'
              ghost
            >
              Batalkan
            </Button>
          </Col>
          <Col>
            <Button onClick={handleSubmit} type='primary'>
              Ya
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal centered closable={false} visible={modalSuccess} footer={null}>
        <Result
          status='success'
          title='Berhasil'
          subTitle='Brand / Supplier baru berhasil ditambahkan'
          extra={[
            <Button
              onClick={() => setModalSuccess(false)}
              type='primary'
              key='console'
            >
              OKE
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
}

export default CreateBrandPage;

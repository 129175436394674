import {
  Button,
  Card,
  Col,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { first } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import inboundCommand from 'shared/commands/inbound.command';
import purchaseOrderCommand, {
  detailPurchaseOrderCommand,
} from 'shared/commands/purchase-order.command';
import HasPermissionGroup from 'shared/components/has-permission';
import { useListPurchaseOrderQuery } from 'shared/queries/purchase-order/purchase-order.query';

const { Text, Link, Title } = Typography;

function PreOrderListPage() {
  const [purchaseOrderStatus, setPurchaseOrderStatus] = useState<
    number | string
  >('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keywords, setKeywords] = useState('');

  const {
    data: purchaseOrders,
    isLoading,
    refetch,
  } = useListPurchaseOrderQuery({
    status: purchaseOrderStatus as any,
    page: currentPage,
    limit: 10,
    sort_by: 'created_at',
    dir: 'DESC',
    q: keywords,
  });

  const navigate = useNavigate();

  const fullInbound = async (id: number) => {
    detailPurchaseOrderCommand(Number(id)).then((response) => {
      const products: any = [];

      const productsRaw = response.data.data.row.Product || [];

      for (let index = 0; index < productsRaw.length - 1; index++) {
        const restQty =
          productsRaw[index].qty_alocation - productsRaw[index].qty_real;

        products.push({
          sku: productsRaw[index].sku,
          qty_arrival: restQty,
          qty_qc: restQty,
          warehouse_id: response.data.data.row.warehouse_id,
          note: '',
        });
      }

      return inboundCommand.createInboundCommand({
        po_id: id,
        qc_datetime: '',
        transaction_datetime: dayjs().format('YYYY-MM-DD HH:MM:ss'),
        products: products,
      });

      // products.push({
      //   sku: productSku,
      //   qty_arrival: values.products[productSku].inbound_stock,
      //   qty_qc: values.products[productSku].inbound_stock,
      //   warehouse_id: values.products[productSku].warehouse,
      //   note: values.note || '',
      // });
    });
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a: any, b: any, index: any) =>
        index + 1 + (currentPage - 1) * 10,
    },
    {
      title: 'ID Settlement',
      key: 'number',
      render: (data: any) => (
        <>
          <Col>
            <Link target='_blank'>
              <Text
                strong
                onClick={() => navigate('/d/purchase-orders/' + data.id)}
              >
                {data.number}
              </Text>
            </Link>
          </Col>
          <Text disabled>{data.created_at}</Text>
        </>
      ),
    },
    {
      title: 'Pembuat Settlement',
      dataIndex: 'created_by_name',
      key: 'created_by_name',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
    },
    {
      title: 'Jumlah Produk',
      dataIndex: 'product_count',
      key: 'product_count',
    },
    {
      title: 'Total',
      dataIndex: 'grand_total',
      key: 'grand_total',
      render: (grand_total: number) => (
        <>
          <p>Rp. {grand_total.toLocaleString('id')}</p>
        </>
      ),
    },
    {
      title: 'Status',
      render: (data: any) => {
        let color = 'blue';
        const status = parseInt(data.status);
        let actionButton = <></>;

        if ([1].includes(status)) {
          // Approve
          color = 'geekblue';
        }

        if ([2].includes(status)) {
          // Paid
          color = 'purple';
        }

        if ([3].includes(status)) {
          // Finish
          color = 'green';
        }

        if ([8, 9].includes(status)) {
          // Close, Reject
          color = 'volcano';
        }

        if (status == 0) {
          actionButton = (
            <Space>
              <HasPermissionGroup
                as={Popconfirm}
                permissions={'po.change_status.approved'}
                title='Acc PO?'
                onConfirm={() => {
                  return purchaseOrderCommand
                    .changeStatusPurchaseOrderRequest(data.id, {
                      status: 1,
                      note: '',
                    })
                    .then(() => refetch());
                }}
              >
                <Button size='small'>Set Acc Settlement</Button>
              </HasPermissionGroup>

              {/* TODO: ADD PERMISSION */}
              <HasPermissionGroup
                as={Popconfirm}
                title='Reject PO?'
                onConfirm={() => {
                  return purchaseOrderCommand
                    .changeStatusPurchaseOrderRequest(data.id, {
                      status: 9,
                      note: '',
                    })
                    .then(() => refetch());
                }}
              >
                <Button size='small'>Set Rejected Settlement</Button>
              </HasPermissionGroup>
            </Space>
          );
        }

        // if (status == 1) {
        //   actionButton = (<Space>
        //     <Popconfirm
        //       title="Tandai sudah dibayar?"
        //       onConfirm={() => {
        //         return purchaseOrderCommand.changeStatusPurchaseOrderRequest(data.id, {
        //           status: 2,
        //           note: '',
        //         }).then(() => refetch());
        //       }}
        //     >
        //       <Button size="small">Set Terbayar</Button>
        //     </Popconfirm>
        //   </Space>);
        // }

        if (status == 2) {
          actionButton = (
            <Space>
              <HasPermissionGroup
                as={Popconfirm}
                permissions={'po.change_status.finish'}
                title='Tandai sudah PO Selesai?'
                onConfirm={() => {
                  // full finish the data.
                  // console.log(data);
                  return fullInbound(data.id).then(() => {
                    purchaseOrderCommand
                      .changeStatusPurchaseOrderRequest(data.id, {
                        status: 3,
                        note: '',
                      })
                      .then(() => refetch());
                  });
                }}
              >
                <Button size='small'>Set Selesai</Button>
              </HasPermissionGroup>
            </Space>
          );
        }

        return (
          <>
            <Tag color={color} key={status} style={{ marginBottom: '8px' }}>
              {data.status_label.toUpperCase()}
            </Tag>
            <br />
            {actionButton}
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data: any) => (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <HasPermissionGroup
                as={Button}
                permissions={'po.detail'}
                type='primary'
                onClick={() =>
                  window.open('/d/purchase-orders/' + data.id, '_blank')
                }
              >
                Lihat Detail
              </HasPermissionGroup>
            </Col>
            {data.status == 0 && (
              <Col>
                <HasPermissionGroup
                  as={Button}
                  permissions={'po.update'}
                  type='primary'
                  ghost
                  onClick={() =>
                    navigate('/d/purchase-orders/' + data.id + '/edit')
                  }
                >
                  Ubah
                </HasPermissionGroup>
              </Col>
            )}
          </Row>
        </>
      ),
    },
  ];

  function onChange(e: any) {
    setPurchaseOrderStatus(e);
  }

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Title level={3}>Daftar Settlement</Title>
      <Card
        title={
          <>
            <Row gutter={15}>
              <Col span={3}>
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { label: 'Semua Settlement', value: '' },
                    { label: 'Settlement Masuk', value: 0 },
                    { label: 'Settlement Acc', value: 1 },
                    { label: 'Settlement Terbayar', value: 2 },
                    { label: 'Settlement Sukses', value: 3 },
                    { label: 'Settlement Batal', value: 8 },
                    { label: 'Settlement Ditolak', value: 9 },
                  ]}
                  onSelect={onChange}
                  value={purchaseOrderStatus}
                />
              </Col>
              <Col span={12}>
                <Input
                  style={{ width: '100%' }}
                  placeholder='Ketikan No Settlement'
                  value={keywords}
                  onChange={(data) => setKeywords(data.target.value)}
                />
              </Col>
              <div className='custom-button' style={{ marginLeft: 'auto' }}>
                <HasPermissionGroup
                  as={Button}
                  permissions={'po.create'}
                  type='primary'
                  style={{ backgroundColor: '#47A0AF !important' }}
                  onClick={() => navigate('/d/purchase-orders/create')}
                >
                  Buat Settlement
                </HasPermissionGroup>
              </div>
            </Row>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={purchaseOrders?.data.data.list}
          bordered
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
        />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={currentPage}
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              total={purchaseOrders?.data.data.total}
            />
          </Col>
        </Row>
      </Card>
      {/* <Row>
        <Col>
          
        </Col>
      </Row> */}
      {/* <Row justify='space-between' style={{ marginTop: '3rem' }}>
        <Col span={6}>
          <h4>List PO</h4>
        </Col>
        <Col span={6}>
          <h4>Total PO : {purchaseOrders?.data.data.total}</h4>
        </Col>
        <Col span={12}>
          <h4>Tanggal: 01/01/2020 - 01/01/2020</h4>
        </Col>
      </Row> */}
    </>
  );
}

export default PreOrderListPage;

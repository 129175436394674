import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Space,
  Table,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useState } from 'react';

function ProductTypesPage() {
  const [modalAddProductTypes, setModalAddProductTypes] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const handleAddProduct = () => {
    setModalAddProductTypes(!modalAddProductTypes);
  };

  const handleSubmit = () => {
    setModalAddProductTypes(!modalAddProductTypes);
    setModalSuccess(!modalSuccess);
  };

  const handleSuccess = () => {
    setModalSuccess(!modalSuccess);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a:any, b:any, index:any) => index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Jenis Barang',
      dataIndex: 'types',
      key: 'types',
    },
    {
      title: 'Total Barang',
      dataIndex: 'total',
      key: 'total',
    },
    // {
    //   title: 'Aksi',
    //   key: 'action',
    //   render: () => (
    //     <Space size='middle'>
    //       <a>Ubah</a>
    //     </Space>
    //   ),
    // },
  ];

  const data = [
    {
      key: '1',
      id: 'WBZ123456789',
      types: 'Makanan',
      total: 40,
    },
    {
      key: '2',
      id: 'WBZ123456789',
      types: 'Minuman',
      total: 20,
    },
  ];

  return (
    <>
      <Title level={3}>Daftar Tipe Barang</Title>
      <Card
      // title={
      //   <Row justify='space-between' align='middle'>
      //     <Col flex={1} style={{ textAlign: 'right' }}>
      //       <Button onClick={handleAddProduct} type='primary'>
      //         Tambah Tipe Barang
      //         </Button>
      //     </Col>
      //   </Row>
      // }
      >
        <Table scroll={{ x: 726 }} columns={columns} dataSource={data} bordered pagination={false} />
      </Card>
      {/* Modal add */}
      <Modal
        title='Status'
        centered
        cancelText='Batalkan'
        okText='Submit'
        onCancel={handleAddProduct}
        onOk={handleSubmit}
        visible={modalAddProductTypes}
      >
        <Form layout='vertical'>
          <Form.Item label='Nama jenis barang' required>
            <Input placeholder='Nama jenis barang' />
          </Form.Item>
        </Form>
      </Modal>
      {/* Modal success */}
      <Modal visible={modalSuccess} centered closable={false} footer={null}>
        <Result
          status='success'
          title='Berhasil'
          subTitle='Jenis barang baru berhasil ditambahkan'
          extra={[
            <Button onClick={handleSuccess} type='primary' key='console'>
              OKE
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
}

export default ProductTypesPage;

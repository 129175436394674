import { Button, Card, Form, Input, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tokenBroker } from 'shared/apis';
import { RoleBroker as RoleBroker, UserBroker } from 'shared/apis/token-broker';
import commands from 'shared/commands';

function LoginPage() {
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: { phone: string; password: string }) => {
    setSending(true);
    const request = commands.auth.login({
      ...values,
      phone: String(values.phone),
    });
    request
      .then((response) => {
        tokenBroker.put(response.data.data?.token);
        RoleBroker.put(response.data.data?.user?.role_id);
        UserBroker.put(response.data.data?.user?.role?.name);
        message.success('Anda telah login', 1).then(() => {
          navigate('/d');
        });
      })
      .catch((e) => {
        message.error(e.response.data.message);
        message.error('Error');
      })
      .finally(() => {
        setSending(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Failed:', errorInfo);
  };

  return (
    <Card title='Masuk'>
      <Form
        name='basic'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <Form.Item
          label='Nomor Handphone'
          name='phone'
          rules={[{ required: true, message: 'Masukan nomor handphone!' }]}
        >
          <Input placeholder='input phone number' />
        </Form.Item>
        <Form.Item
          label='Kata Sandi'
          name='password'
          rules={[{ required: true, message: 'Masukan kata sandi!' }]}
        >
          <Input.Password placeholder='input password' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8 }}>
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '50%' }}
            loading={sending}
          >
            Masuk
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default LoginPage;

import { useQuery } from "react-query";
import { stockProductCommand, stockProductDetailCommand } from "shared/commands/product.command";

type Props = {
  limit?: number;
  page?: number;
  sku?: string;
  keyword?: string;
}

export const useProductStockQuery = (props?: Props) => {
  const queries = useQuery(['product stock', props],
    async () => {
      const response = await stockProductCommand(props);
      return response;
    });
  return queries;
}


export const useProductStockDetailQuery = (sku: string) => {
  const queries = useQuery(['product stock detail', sku],
    async () => {
      const response = await stockProductDetailCommand(sku);
      return response;
    });
  return queries;
}
import { useQuery } from 'react-query';
import {
  inboundDetailCommand,
  InboundRequestType,
  listInboundCommand,
} from 'shared/commands/inbound.command';
// import { listWarehouseCommand, WarehouseListRequest } from 'shared/commands/warehouse.command';

export const useListInboundQuery = (props?: InboundRequestType) => {
  const queries = useQuery(['inbounds', props], async () => {
    const response = await listInboundCommand({ limit: 10, page: 1, ...props });
    return response;
  },{
    enabled:((props?.po_id||0)>0)
  });
  return queries;
};

export const useInboundQuery = (id:number|string) => {
  const queries = useQuery(['inbounds', id],
    async () => {
      const response = await inboundDetailCommand(id);
      return response;
    },{
      enabled:id>0
    });
  return queries;
}

import { wrapApi } from 'shared/apis';
import api, { ApiResponse } from 'shared/apis/api';

export interface List {
  id: number;
  name: string;
  address?: any;
  province?: any;
  city?: any;
  phone: string;
  bank_name: string;
  bank_account_name?: any;
  bank_account_number: string;
  created_at: string;
  updated_at?: any;
}

export interface Data {
  limit: number;
  list: List[];
  page: number;
  total: number;
  total_page: number;
  total_data: number;
}

export interface RootObject {
  status: number;
  message: string;
  data: Data;
}

export type listUserRequest = {
page?:number
limit?:number
sort?:"account_id" | "account_role_id" | "account_username" | "account_email" | "account_fullname" | "account_status" | "last_login_at" | "created_at" | "updated_at]"
dir?:"desc"|"asc"
}

const listUserCommand = <T>(data:listUserRequest) => {
  return api<T, ApiResponse<Data>>(
    'GET',
    wrapApi('/users/v1/list',data)
  );
};

const detailUserCommand = <T>(id:number) => {
  return api<T, ApiResponse<any>>(
    'GET',
    wrapApi('/users/v1/detail/'+id)
  );
};

const createUserCommand = <T={role_id:string;fullname:string;email:string;status:string;phone:string;password:string;conf_password:string;}>(data:T) => {
  return api<T, ApiResponse<Data>>(
    'POST',
    wrapApi('/users/v1/create'), data
  );
};

const listRolesCommand = <T>(data:listUserRequest) => {
  return api<T, ApiResponse<Data>>(
    'GET',
    wrapApi('/roles/v1/list',data)
  );
};

const createRoleCommand = <T={role_id:string;fullname:string;email:string;status:string;phone:string;password:string;conf_password:string;}>(data:T) => {
  return api<T, ApiResponse<any>>(
    'POST',
    wrapApi('/roles/v1/create'), data
  );
};

const detailRoleCommand = <T>(id:T) => {
  return api<T, ApiResponse<any>>(
    'GET',
    wrapApi('/roles/v1/detail/'+id)
  );
};

const syncPermissionRole = <T={role_id:number, permission_id:number[]}>(data:T) => {
  return api<T, ApiResponse<any>>(
    'PUT',
    wrapApi('/roles/v1/permission/update'), data
  );
};

const listPermissionOfRoles  = <T>(id:T) => {
  return api<T, ApiResponse<any>>(
    'GET',
    wrapApi('/roles/v1/permission/list/'+id, {limit:-1})
  );
};

const listPermissionCommand = <T>(data:listUserRequest) => {
  return api<T, ApiResponse<Data>>(
    'GET',
    wrapApi('/permission/v1/list',data)
  );
};

export {
  createRoleCommand,
  createUserCommand,
  detailRoleCommand,
  detailUserCommand,
  listPermissionCommand,
  listPermissionOfRoles,
  listRolesCommand,
  listUserCommand,
  syncPermissionRole};
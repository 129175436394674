import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography} from 'antd';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListReportPurchaseOrder } from 'shared/queries/purchase-order/purchase-order.query';

import ExportReportPO from './export-report-po';

const { RangePicker } = DatePicker;

const { Text, Link, Title } = Typography;

function PreOrderListPage() {
  const [keywords, setKeywords] = useState('');
  const [daterange, setDateRange] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState<number | string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dateFilters = useMemo(()=>{
    console.log(daterange.length);
    if(daterange.length == 2) {
      return {
        start_date:(daterange[0] as any).format("YYYY-MM-DD"),
        end_date:(daterange[1] as any).format("YYYY-MM-DD")
      }
    }
    return {};
  },[daterange]);

  const { data: purchaseOrders, isLoading } = useListReportPurchaseOrder({
    page: currentPage,
    limit: 10,
    sort_by: 'created_at',
    dir: 'DESC',
    is_paid: paymentStatus as any,
    q: keywords,
    ...dateFilters
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      render: (a: any, b: any, index: any) => (index + 1) + ((currentPage - 1) * 10),
    },
    {
      title: 'ID Settlement',
      key: 'number',
      render: (data: any) => (
        <>
          <Col>
            <Link target="_blank">
              <Text strong onClick={() => navigate('/d/purchase-orders/' + data.id)}>
                {data.number}
              </Text>
            </Link>
          </Col>
          <Text disabled>{data.created_at}</Text>
        </>
      ),
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
    },
    {
      title: 'Tipe Pembayaran',
      dataIndex: 'supplier_payment',
      key: 'supplier_payment',
    },
    {
      title: 'Status Pembayaran',
      dataIndex: 'is_paid',
      key: 'is_paid',
      render: (data: any) => data == "1" ? "Sudah dibayar" : "Belum dibayar"
    },
    {
      title: 'Selisih Hari',
      dataIndex: 'selisih_hari',
      key: 'selisih_hari',
    },
    {
      title: 'Status',
      render: (data: any) => {
        let color = 'geekblue';
        const status = parseInt(data.status);

        if ([1, 2, 3].includes(status)) {
          // Approve, Paid, Finish
          color = 'green';
        }

        if ([8, 9].includes(status)) {
          // Close, Reject
          color = 'volcano';
        }

        return (
          <>
            <Tag color={color} key={status} style={{ marginBottom: "8px" }}>
              {data.status_label.toUpperCase()}
            </Tag><br />
          </>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (data: any) => (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                type='primary'
                onClick={() => window.open('/d/purchase-orders/' + data.id, "_blank")}
              >
                Lihat Detail
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Title level={3}>Laporan Settlement</Title>
      <Card
        title={
          <>
            <Row justify='space-between'>
              <Col span={16}>
                <Space>
                  <Select
                    style={{ textAlign: 'center' }}
                    options={[
                      { label: 'Semua Status Pembayaran', value: '' },
                      { label: 'Terbayar', value: 1 },
                      { label: 'Belum Dibayar', value: 0 },
                    ]}
                    onSelect={setPaymentStatus}
                    value={paymentStatus}
                  />
                  <Input style={{ width: 300 }} placeholder='Ketikan Nama Supplier / No PO' value={keywords} onChange={(data) => setKeywords(data.target.value)} />
                  <RangePicker onChange={(data:any) => setDateRange(data || [])} />
                </Space>
              </Col>
              <Col>
                <ExportReportPO {...{
                  sort_by: 'created_at',
                  dir: 'DESC',
                  is_paid: paymentStatus as any,
                  q: keywords,
                  ...dateFilters
                }} />
              </Col>
            </Row>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={purchaseOrders?.data.data.list}
          bordered
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
        />
        <Row style={{ marginTop: '1rem' }} justify='end'>
          <Col>
            <Pagination
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={currentPage}
              onChange={handleChangePagination}
              defaultCurrent={currentPage}
              total={purchaseOrders?.data.data.total}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default PreOrderListPage;

import { useQuery } from "react-query";
import { listOutbound } from "shared/commands/outbound.command";

export const useListOutbound = (filter:any) => {
    const queries = useQuery(['outbounds', filter],
      async () => {
        const response = await listOutbound(filter);
        return response;
      });
    return queries;
  }
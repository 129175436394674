import { useQuery } from 'react-query';
import { listOutboundBySku } from 'shared/commands/outbound.command';
import {
  detailPurchaseOrderCommand,
  listPurchaseOrderCommand,
  poBySkuCommand,
  poInboundReport,
  PurchaseOrderListRequest,
} from 'shared/commands/purchase-order.command';

export const useListPurchaseOrderQuery = (props?: PurchaseOrderListRequest) => {
  const queries = useQuery(['purchaseOrders', props], async () => {
    const response = await listPurchaseOrderCommand({
      limit: 10,
      page: 1,
      ...props,
    });
    return response;
  });
  return queries;
};

export const useDetailPurchaseOrderQuery = (id: number) => {
  const queries = useQuery(['purchase order detail', id], async () => {
    const response = await detailPurchaseOrderCommand(id);
    return response;
  });
  return queries;
};

export const useDetailBySkuQuery = (id: number, page: number) => {
  const queries = useQuery(
    ['purchase order detail bysku', id, page],
    async () => {
      const response = await poBySkuCommand(id, page);
      return response;
    }
  );
  return queries;
};

export const useOutboundQuery = (id: number, page:number, sort?: string, dir?:string) => {
  const queries = useQuery(
    ['outbound by sku', id, page],
    async () => {
      const response = await listOutboundBySku(id, page, sort, dir);
      return response;
    }
  );
  return queries;
};


export const useListReportPurchaseOrder = (props?: {
  limit?:number;
  page?:number;
  sort_by?:"number"|"created_at"|"paid_at"|"finished_at";
  dir?:"ASC"|"DESC";
  q?:string;
  is_paid?:1|0;
  start_date?:string;
  end_date?:string;
}) => {
  const queries = useQuery(['purchaseOrdersReport', props], async () => {
    const response = await poInboundReport({
      limit: 10,
      page: 1,
      ...props,
    });
    return response;
  });
  return queries;
};
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { syncPermissionRole } from 'shared/commands/user.command';
import { userPermissionListQuery, userRoleQuery, userRolesPermissionsQuery } from 'shared/queries/user/users.query';

function EditRolePage() {
  const { id } = useParams();
  const role = userRoleQuery(id as any);
  const rolePermissions = userRolesPermissionsQuery(id as any);
  const [selectedPermissions, setSelectedPermissions]= useState<any>([]);
  const navigate = useNavigate();

  const permissions = userPermissionListQuery({
    page: 1,
    limit: -1,
  });

  useEffect(()=>{
    if(rolePermissions.isSuccess && !rolePermissions.isLoading){
      const permissionFromApi = ((rolePermissions.data?.data.data.list || []) as Array<any>).map((ps)=> Number(ps.permission_id));
      setSelectedPermissions(permissionFromApi);
    }
  },[rolePermissions.isSuccess]);
  // permission_id

  const onFinish = ()=>{
    syncPermissionRole({
      role_id: Number(id),
      permission_id:selectedPermissions
    }).then(() => {
      message.success("Role baru telah dibuat");
      navigate('/d/roles');
    }).catch((error) => {
      if(Object.keys(error.response.data.validation).length > 0){
        const keyname = Object.keys(error.response.data.validation)[0]
        message.error(error.response.data.validation[keyname]);
      }
    });
  }

  if(role.isLoading){
    return <></>;
  }

  return (
    <>
      <Form
        name='dynamic_form_nest_item'
        autoComplete='off'
        layout='vertical'
        onFinish={onFinish}
      >
        <Card title='Ubah Peran' style={{ marginBottom: '2em' }}>
          <Row gutter={52}>
            <Col xs={24} xl={12} span={12}>
              <Form.Item
                name='name'
                label='Nama Peran'
                initialValue={role.data?.data.data.name}
                rules={[
                  {
                    required: true,
                    message: 'Nama Peran harus diisi',
                  }, 
                  {
                    max: 25,
                    message: 'Nama Peran tidak boleh lebih dari 25 karakter'
                  }
                ]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Checkbox.Group style={{ width: '100%' }} name='permissions' onChange={setSelectedPermissions} value={selectedPermissions}>
          <Row gutter={52} style={{ marginBottom:50 }}>
            {permissions.data?.data.data.list.map((permission:any)=>(
              <Col xs={24} xl={6} span={6} style={{ marginBottom:12 }}>
                <Checkbox value={permission.id}>
                  {permission.title}
                </Checkbox>
              </Col>
            ))}
          </Row>
          </Checkbox.Group>
          <Button type='primary' htmlType='submit'>Simpan</Button>
        </Card>
      </Form>
    </>
  );
}

export default EditRolePage;

import { Col, Pagination, Row, Space, Table, Tag, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProductStockDetailQuery } from 'shared/queries/product/product.query';
import {
  useDetailBySkuQuery,
  useOutboundQuery,
} from 'shared/queries/purchase-order/purchase-order.query';

import ExportOutbound from './export-outbound';
import ExportPOHistory from './export-po-history';

const { Title } = Typography;

function ProductStockDetailPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { id } = useParams();
  const { data, isLoading } = useProductStockDetailQuery(id || '');
  const poHistory = useDetailBySkuQuery(id as any, currentPage);
  const outbounds = useOutboundQuery(id as any, 1, 'created_at', 'desc');

  const columns = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: 'SKU ID',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Gudang',
      dataIndex: 'warehouse',
      key: 'warehouse',
    },
    {
      title: 'Total Stock',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  const columns2 = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (a: any, b: any, index: any) =>
        index + 1 + (currentPage - 1) * 10,
    },
    {
      title: 'PO Number',
      dataIndex: 'po_number',
      key: 'po_number',
    },
    {
      title: 'Gudang',
      dataIndex: 'warehouse',
      key: 'warehouse',
    },
    {
      title: 'Harga Beli',
      dataIndex: 'buy_price',
      key: 'buy_price',
      render: (buy_price: number) => (
        <>
          <p>Rp. {buy_price.toLocaleString('id')}</p>
        </>
      ),
    },
    {
      title: 'Total Stock',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: 'Jumlah Alokasi',
      dataIndex: 'qty_alocation',
      key: 'qty_alocation',
    },
    {
      title: 'Dibuat Oleh',
      dataIndex: 'creator_name',
      key: 'creator_name',
    },
    {
      title: 'Status',
      render: (data: any) => {
        let color = 'geekblue';
        const status = parseInt(data.status);

        if ([1, 2, 3].includes(status)) {
          // Approve, Paid, Finish
          color = 'green';
        }

        if ([8, 9].includes(status)) {
          // Close, Reject
          color = 'volcano';
        }

        return (
          <>
            <Tag color={color} key={status} style={{ marginBottom: '8px' }}>
              {data.status_label.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
  ];

  const outboundColumn = [
    {
      title: 'No',
      dataIndex: 'index',
      key: 'index',
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (day: string) => dayjs(day).format('DD-MM-YYYY'),
    },
    {
      title: 'Order No',
      dataIndex: 'order_no',
      key: 'order_no',
    },
    {
      title: 'Nama Mitra',
      dataIndex: 'mitra_name',
      key: 'mitra_name',
    },
    {
      title: 'Nomor Mitra',
      dataIndex: 'mitra_phone',
      render: (data: any) => (
        <Space size='middle'>
          <div>{data}</div>
        </Space>
      ),
    },
    {
      title: 'Kuantitas',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Grand Total',
      dataIndex: 'grand_total',
      key: 'grand_total',
      render: (grand_total: number) => (
        <>
          <p>Rp. {grand_total.toLocaleString('id')}</p>
        </>
      ),
    },
  ];

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Table
        loading={isLoading}
        scroll={{ x: 726 }}
        columns={columns}
        dataSource={data?.data.data[0]}
        bordered
        pagination={false}
        style={{ marginBottom: '2em' }}
      />

      <Title level={4} style={{ marginTop: '3em' }}>
        <Row justify='space-between'>
          <Col>History Settlement</Col>
          <Col>
            <ExportPOHistory id={id as any} />
          </Col>
        </Row>
      </Title>
      <Table
        loading={poHistory.isLoading}
        scroll={{ x: 726 }}
        columns={columns2}
        dataSource={poHistory.data?.data.data.list || []}
        bordered
        pagination={false}
        style={{ marginBottom: '2em' }}
      />
      <Row style={{ marginTop: '1rem' }} justify='space-between'>
        <Col style={{ border: '1px solid black', padding: '10px' }}>
          <Row>
            <Col>
              {' '}
              <Title level={4}>Keterangan</Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>Jumlah Alokasi = Jumlah yang diminta didalam Settlement</Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                Jumlah Asli = Jumlah yang diterima selama proses inbound
                (maksimal se-jumlah alokasi )
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>Stok = Jumlah barang yang bisa dijual</Text>
            </Col>
          </Row>
        </Col>
        <Col>
          <Pagination
            current={currentPage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            onChange={handleChangePagination}
            defaultCurrent={currentPage}
            total={poHistory.data?.data.data.total_data}
          />
        </Col>
      </Row>
      <Title level={4} style={{ marginTop: '3em' }}>
        <Row justify='space-between'>
          <Col>Outbound</Col>
          <Col>
            <ExportOutbound id={id as any} />
          </Col>
        </Row>
      </Title>
      <Table
        loading={outbounds.isLoading}
        scroll={{ x: 726 }}
        columns={outboundColumn}
        dataSource={outbounds.data?.data.data.list || []}
        bordered
        style={{ marginBottom: '2em' }}
      />
    </>
  );
}

export default ProductStockDetailPage;

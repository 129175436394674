import { Button, Col, Drawer, Form, Input, message, Row, Space } from 'antd';
import { ProductCard } from 'pages/dashboard/inbound/inbound-stock/inbound-stock.page';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import inboundCommand from 'shared/commands/inbound.command';
import { detailPurchaseOrderCommand } from 'shared/commands/purchase-order.command';

const NewInboundDrawer = (props: {
  show: boolean;
  poId: string;
  warehouseId: string;
  onChange: (value: any) => void;
  onSuccess: () => void;
}) => {
  const [onSending, setOnSending] = useState(false);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    onPurchaseOrderNumberChange(props.poId);
  }, [props.poId]);

  const onPurchaseOrderNumberChange = (value: any) => {
    detailPurchaseOrderCommand(value).then((response) => {
      setProducts(response.data.data.row.Product || []);
    });
  };

  const onFinish = (values: any) => {
    const products: any = [];
    const productIds = Object.keys(values.products);
    let dataComplete = false;
    let count = 1;
    let countNull = 1;

    if (values.note === undefined) {
      message.error(`Catatan harus diisi`);
      dataComplete = false;
    }
    productIds.forEach((productSku) => {
      if (values.products[productSku].inbound_stock === undefined) {
        message.error(`Data pada produk ${count} yang belum diisi`);
        dataComplete = false;
      } else {
        if (values.products[productSku].inbound_stock !== 0) {
          products.push({
            sku: productSku,
            qty_arrival: values.products[productSku].inbound_stock,
            qty_qc: values.products[productSku].inbound_stock,
            warehouse_id: props.warehouseId,
            note: values.note || '',
          });
          dataComplete = true;
        }
        countNull++;
      }
      count++;
    });

    if (!dataComplete && countNull == count) {
      message.error(`Produk yang anda masukkan stok masih 0 semua!`);
    } else {
      setOnSending(true);
      inboundCommand
        .createInboundCommand({
          po_id: parseInt(props.poId),
          qc_datetime: '',
          transaction_datetime: '2021-12-25 14:23:42',
          products: products,
        })
        .then(() => {
          props.onChange(false);
          message.success('Inbound stok telah disimpan');
          props.onSuccess();
        })
        .catch((error) => {
          message.error(error.response.data.message);
        })
        .finally(() => {
          setOnSending(false);
        });
    }
  };

  return (
    <>
      <Drawer
        title='Inbound Stok'
        width={isMobile ? 320 : 720}
        onClose={() => {
          props.onChange(false);
        }}
        visible={props.show}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form onFinish={onFinish}>
          <Row gutter={32}>
            <Col flex={3}>
              <Form.Item label='Catatan' name='note'>
                <Input placeholder='Tambah Catatan' />
              </Form.Item>
            </Col>
          </Row>

          {products.map((product, index) => (
            <ProductCard
              key={product.id}
              sequence={index + 1}
              product={product}
            />
          ))}

          <Space>
            <Button
              onClick={() => {
                props.onChange(false);
              }}
            >
              Batal
            </Button>
            <Button type='primary' htmlType='submit' loading={onSending}>
              Simpan
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default NewInboundDrawer;

import { Button } from "antd";
import { useEffect, useState } from "react";
import { listInboundCommand } from "shared/commands/inbound.command";
import downloadxls from "shared/components/downloadxls";

function ExportInbound(props:{id:number; filename?:string}){
    const [isLoading, setIsLoading] = useState(false);
    const [fileIsReady, setFileIsReady] = useState(false);
    const [data, setData] = useState([]);
    useEffect(()=>{
        setIsLoading(true);
        listInboundCommand({po_id:props.id, limit:999999999, page:1}).then((response)=>{
            const newArrayInbound = [] as any;
            response.data.data.list.forEach((item, idx)=>{
                const extractedInbound = new Object() as any;

                extractedInbound.No = idx + 1;
                extractedInbound['ID Inbound'] = item.id;
                extractedInbound['Tanggal Masuk'] = item.created_at;
                extractedInbound['SKU'] = item.product.sku;
                extractedInbound['Nama Barang'] = item.product.name;
                extractedInbound['Stock Masuk'] = `${item.warehouse.name}: ${item.qty_arrival} Satuan`;
                extractedInbound['Harga Beli'] = item.product.price_estimation;
                extractedInbound['Catatan'] = item.note;

                newArrayInbound.push(extractedInbound);
            })
            setData(newArrayInbound);
        }).finally(()=>{
            setIsLoading(false);
        })  
    },[props.id]);

    return <Button onClick={()=>downloadxls(props.filename, data, [[
        'No',
        'ID Inbound',
        'Tanggal Masuk',
        'SKU',
        'Nama Barang',
        'Stock Masuk',
        'Harga Beli',
        'Catatan',
      ]])}>
        Download Excel
    </Button>
}

export default ExportInbound;
import * as XLSX from 'xlsx';

const downloadxls = (filename:any,data:any, header:any = []) => {
    console.log(header);

    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, header);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    const buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'});
    const str = XLSX.write(wb, {bookType:'xlsx', type:'binary'});

    XLSX.writeFile(wb, filename);
}

export default downloadxls;